import { delay } from 'dva/saga';
import { notification } from 'antd';

import { serviceDeletePulseCollector } from '@/services/singlePulse';
import ConfigSite from '@/../config/configSite';
import messageSuccess from '@/components/CustomizedMessage/success';

function* deletePulseCollector({ payload }: any, { call, take, race, put, select }: any) {
  try {
    const { pulseId, collectorId, callback } = payload;

    // Remove collector from store
    const oldCollectors: any[] = yield select((model: any) => model.pulse.collectors);
    const newCollectors: any[] = oldCollectors.filter(
      collector => collectorId !== collector?.collectorId
    );

    yield put({
      type: 'savePulseCollectors',
      payload: {
        collectors: newCollectors,
      },
    });

    const { undo, task } = yield race({
      undo: take((action: any) => action.type === `pulse/undoDeletePulseCollector`),
      task: delay(ConfigSite.TIME_FOR_UNDO),
    });

    if (undo) {
      // revert thread to previous state
      yield put({
        type: 'savePulseCollectors',
        payload: {
          collectors: oldCollectors,
        },
      });
      messageSuccess(`You have successfully undo with deleting collector`);
      return;
    }

    if (task) {
      const { meta } = yield call(serviceDeletePulseCollector, pulseId, collectorId);

      if (meta?.ok) {
        if (typeof callback === 'function') {
          callback();
        }

        return notification.success({ message: 'Delete collector successfully' });
      }

      // revert thread to previous state
      yield put({
        type: 'savePulseCollectors',
        payload: {
          collectors: oldCollectors,
        },
      });
      notification.error({
        message: 'Failed to delete pulse sponsors',
        description: meta?.message,
      });
    }
  } catch (error) {
    notification.error({ message: 'Something went wrong' });
  }
  return true;
}

export default deletePulseCollector;
