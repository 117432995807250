export default function savePulses(
  state: any,
  action: { payload: { pulses: []; pagination: {} } }
) {
  return {
    ...state,
    pulses: action?.payload?.pulses,
    pagination: action?.payload?.pagination,
  };
}
