import { getHistorySurvey } from '@/services/history';

export default {
  namespace: 'historySurvey',
  state: {
    activities: [],
  },
  effects: {
    *fetch({ payload }, { call, put }) {
      const { slug } = payload;
      const response = yield call(getHistorySurvey, slug);
      if (response.isSuccess) {
        const { data } = response;
        yield put({
          type: 'append',
          payload: data,
        });
      }
    },
  },

  reducers: {
    append(state, { payload }) {
      return {
        ...state,
        activities: payload,
      };
    },
  },
};
