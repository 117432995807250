import { Question } from '@/types/question';
import { cloneDeep } from 'lodash';

export default function saveKeywords(
  state: QuestionModel.QuestionModelState,
  { payload }: { payload: any }
) {
  // Suspicious
  const { keywords } = state;
  const newKeywords = cloneDeep(keywords);
  const foundIndex = newKeywords.findIndex(
    (item: Question) => item.questionId === payload?.questionId
  );
  if (foundIndex < 0) newKeywords.push(payload);
  else {
    newKeywords[foundIndex] = payload;
  }
  return {
    ...state,
    keywords: newKeywords,
  };
}
