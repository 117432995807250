import { produce } from 'immer';

export default function updatePulseStatus(
  state: any,
  action: { payload: { pulseId: string; status: null } }
) {
  const { pulseId, status } = action.payload;
  const newPulses = produce(state.pulses, (pulses: any[]) => {
    const findIndexPulse = pulses.findIndex(item => item.pulseId === pulseId);
    pulses[findIndexPulse].pulseStatus = status;
  });
  return {
    ...state,
    pulses: newPulses,
  };
}
