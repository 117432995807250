import savePulse from './savePulse';
import savePulses from './savePulses';
import updatePulseStatus from './updatePulseStatus';
import savePulseSponsors from './savePulseSponsors';
import savePulseCollectors from './savePulseCollectors';

export default {
  savePulse,
  savePulses,
  updatePulseStatus,
  savePulseSponsors,
  savePulseCollectors,
};
