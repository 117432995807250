import { cloneDeep } from 'lodash';

export default function addDraftQuestion(
  state: QuestionModel.QuestionModelState,
  { payload }: { payload: any }
) {
  const { list } = state;
  const newList = cloneDeep(list);
  newList.push(payload);
  return { ...state, list: newList };
}
