import addDraftQuestion from './addDraftQuestion';
import append from './append';
import clearPulselyOptionOfQuestion from './clearPulselyOptionOfQuestion';
import deleteDraft from './deleteDraft';
import reOrder from './reOrder';
import removeQuestion from './removeQuestion';
import saveAvailableBranches from './saveAvailableBranches';
import saveKeywords from './saveKeywords';
import setQuestionBeforeBranching from './setQuestionBeforeBranching';
import updateDefaultQuestion from './updateDefaultQuestion';
import updateOthersAnswerOfQuestionInList from './updateOthersAnswerOfQuestionInList';
import updateQuestion from './updateQuestion';
import updateQuestionFeeling from './updateQuestionFeeling';
import appendFeeling from './appendFeeling';

export default {
  addDraftQuestion,
  append,
  appendFeeling,
  clearPulselyOptionOfQuestion,
  deleteDraft,
  reOrder,
  removeQuestion,
  saveAvailableBranches,
  saveKeywords,
  setQuestionBeforeBranching,
  updateQuestionFeeling,
  updateQuestion,
  updateDefaultQuestion,
  updateOthersAnswerOfQuestionInList,
};
