import { notification } from 'antd';
import { history } from 'umi';
import urlcat from 'urlcat';

import { serviceCreatePulses } from '@/services/singlePulse';
import Path from '@/../config/path';

function* createPulse({ payload }: any, { call }: any) {
  try {
    const { body, callback } = payload;
    const { meta, data } = yield call(serviceCreatePulses, body);
    if (meta?.ok) {
      notification.success({ message: 'Create pulse successfully' });

      history.push(urlcat(Path.SINGLE_PULSE_DETAILS, { id: data?.pulseId }));
      
      if (typeof callback === 'function') {
        callback();
      }
    } else {
      notification.error({ message: 'Failed to create pulse', description: meta?.message });
    }
  } catch (error) {
    notification.error({ message: 'Something went wrong' });
  }
  return true;
}

export default createPulse;
