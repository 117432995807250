import { stringify } from 'qs';
import request from '@/utils/request';
import ConfigSite from '../../config/configSite';

export async function query(
  params = { offset: 0, limit: ConfigSite.LIMIT, order: 'DESC', status: 'active' }
) {
  return request(`/collections?${stringify(params)}`);
}

export async function search(params) {
  return request(`/collections/_search?${stringify(params)}`);
}
export async function create(params) {
  return request('/collections', {
    method: 'POST',
    body: JSON.stringify(params),
  });
}
export async function duplicate(params, pulseCollectionId) {
  return request(`/collections/${pulseCollectionId}/duplicate`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}
export async function addCollector(id, data) {
  return request(`/collections/${id}/collectors`, {
    method: 'PUT',
    body: JSON.stringify(data),
  });
}

export async function addSponsor(id, data) {
  return request(`/collections/${id}/sponsors`, {
    method: 'PUT',
    body: JSON.stringify(data),
  });
}

export async function updateCollection(slug, params) {
  return request(`/collections/${slug}`, {
    method: 'PUT',
    body: JSON.stringify(params),
  });
}

export async function fetchCollectionDetails(id) {
  return request(`/collections/${id}/details`);
}

export async function deletePulseService(pulseId) {
  return request.delete(`/pulses/${pulseId}`);
}

export async function deleteArchiveCollectionService(collectionId, title, status) {
  return request.put(`/collections/${collectionId}`, {
    method: 'PUT',
    data: { title, status },
  });
}

export async function serviceAttachPulses(collectionId, pulseIds) {
  return request(`/collections/${collectionId}/attach-pulses`, {
    method: 'PUT',
    data: JSON.stringify({
      pulseIds,
    }),
  });
}

export async function serviceDetachPulses(collectionId, pulseIds) {
  return request(`/collections/${collectionId}/detach-pulses`, {
    method: 'PUT',
    data: JSON.stringify({
      pulseIds,
    }),
  });
}
