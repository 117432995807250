import { notification } from 'antd';
import { history } from 'umi';
import {
  query as queryUsers,
  loginAdmin,
  login,
  getAnonymousName,
  loginExternalMember,
  register,
  registerAccountV2,
  checkEmailExsits,
  checkToken,
  setupPassword,
  forgotPassword,
  loginWithSmartToken,
  loginWithGoogle,
  loginWithApple,
  getAnonymousToken,
} from '@/services/user';
import {
  setAuthority,
  setToken,
  setRedirect,
  getRedirect,
  setAnonName,
  setUser,
  getUser,
} from '@/utils/authority';
import { reloadAuthorized } from '@/utils/Authorized';
import ConfigSite from '../../config/configSite';

import { subscribeByUserId } from '@/utils/oneSignal';
import { fetchGeneralInfoSponsor } from '@/services/survey';
const { PULSE_TYPE } = ConfigSite;
const UserModel = {
  namespace: 'user',
  state: {
    currentUser: {},
    redirect: {},
    resGenAnon: true,
    isSubscribe: false,
  },
  effects: {
    *register(action, { call, put }) {
      const res = yield call(register, action.payload);
      if (res.isSuccess) {
        const { data } = res;
        yield setUser(data.user);
        yield setAuthority(data.user.userType);
        yield setToken(data.token);
        yield reloadAuthorized();
        yield put({
          type: 'notification',
          payload: true,
        });
      } else {
        yield put({
          type: 'notification',
          payload: false,
        });
      }
    },
    *registerV2({ payload }, { call, put }) {
      const response = yield call(registerAccountV2, payload);
      if (response.isSuccess) {
        const { token, user } = response.data;
        yield setAuthority(user.userType);
        yield setToken(token);
        yield setUser(user);
        yield reloadAuthorized();
        yield put({ type: 'subscribeToOneSignal', payload: user });
        yield put({
          type: 'notification',
          payload: true,
        });
        if (payload?.action === 'checkIn') {
          if (payload?.email)
            yield put({
              type: 'survey/checkIn',
              payload: {
                email: payload.email,
                code: payload?.checkInCode,
                // redirectTo: 'startSurvey',
              },
            });
          else {
            yield put({
              type: 'notification',
              payload: false,
            });
          }
        } else {
          window.location.href = getRedirect() || history.push('/');
        }
      } else {
        yield put({
          type: 'notification',
          payload: false,
        });
      }
    },
    *submitToken({ payload: { token } }, { call, put }) {
      const response = yield call(checkToken, token);
      if (response.isSuccess) {
        const { data } = response;
        const user = {
          name: data.name,
        };
        yield setUser(user);
        yield put({
          type: 'saveCurrentUser',
          payload: user,
        });
      } else {
        history.push(`/`);
        notification.error({
          message: response.getErrorMessage('Failed to setup password'),
        });
      }
    },
    *setupPassword({ payload }, { call, put }) {
      const response = yield call(setupPassword, payload.token, payload.password);
      if (response.isSuccess) {
        const { token, user } = response.data;
        yield setAuthority(user.userType);
        yield setToken(token);
        yield setUser(user);
        yield reloadAuthorized();
        yield put({ type: 'subscribeToOneSignal', payload: user });
        yield put({
          type: 'notification',
          payload: true,
        });
        window.location.href = getRedirect() || HOME_URL;
      } else {
        notification.error({
          message: response.getErrorMessage('Failed to setup password'),
        });
      }
      history.push('/');
    },
    *forgotPassword({ payload: { email } }, { call, put }) {
      const redirect = getRedirect() || HOME_URL;
      const response = yield call(forgotPassword, email, redirect);
      if (response.isSuccess) {
        const user = {
          email,
        };
        yield setUser(user);
        yield put({
          type: 'saveCurrentUser',
          payload: user,
        });
        history.push({ pathname: '/forgot-password' });
      } else {
        notification.error({
          message: response.getErrorMessage('Failed to request forgot password'),
        });
      }
    },
    *saveJWT(action) {
      const { jwt, userType } = action.payload;
      yield setAuthority(userType);
      yield setToken(jwt);
      yield reloadAuthorized();
    },
    *saveRedirect(action) {
      yield setRedirect(action.payload.redirect);
      yield reloadAuthorized();
    },
    *getRedirect(action, { put }) {
      const redirect = yield getRedirect();
      yield put({
        type: 'getRedirectPut',
        payload: redirect,
      });
    },
    *adminLogin(action, { call, put }) {
      const res = yield call(loginAdmin, action.payload);
      const { data } = res;
      if (data) {
        yield put({
          type: 'notification',
          payload: true,
        });
        yield put({
          type: 'jwt',
          payload: data,
        });
        yield setAuthority(ConfigSite.USER_TYPE.ADMIN);
        yield setToken(data);
        yield reloadAuthorized();
        window.location.href = localStorage.getItem('redirect') || '/admin';
      } else {
        yield put({
          type: 'notification',
          payload: false,
        });
      }
    },

    *subscribeToOneSignal({ payload }, { put, select }) {
      const isSubscribe = yield select(({ user }) => user.isSubscribe);
      if (!isSubscribe && payload && payload.userId) {
        yield put({ type: 'updateSubscribe', payload: yield subscribeByUserId(payload.userId) });
      }
    },

    *subscribeToOneSignalIfNotYet(_, { put }) {
      try {
        const user = JSON.parse(getUser());
        yield put({ type: 'subscribeToOneSignal', payload: user });
      } catch (err) {
        yield put({ type: 'login/logout' });
      }
    },

    *fetchCurrent(_, { call, put }) {
      const response = yield call(queryUsers);
      if (response.isSuccess) {
        const { data } = response;
        yield put({
          type: 'saveCurrentUser',
          payload: data,
        });
        setUser(data);
        yield put({ type: 'subscribeToOneSignal', payload: data });
      } else {
        yield put({ type: 'login/logout' });
      }
    },
    *generateAnonyName(_, { call, put }) {
      const response = yield call(getAnonymousName);
      if (response.isSuccess) {
        const { data } = response;
        yield setAnonName(data.username);
        yield setAuthority(ConfigSite.USER_TYPE.GUEST);
        yield setToken(data.token);
        yield reloadAuthorized();
      } else {
        yield put({
          type: 'errorGenerateAnonyName',
          payload: response.meta,
        });
      }
    },
    *loginExternalMember(_, { call, put }) {
      const response = yield call(loginExternalMember, _.payload);
      const { data } = response;
      if (data.isRegistered) {
        const { token, user } = data;
        yield setAuthority(user.userType);
        yield setToken(token);
        yield setUser(user);
        yield reloadAuthorized();
        yield put({ type: 'subscribeToOneSignal', payload: user });
        yield put({
          type: 'notification',
          payload: true,
        });
        const redirect = getRedirect();
        if (redirect) {
          window.location.href = redirect;
        } else {
          history.push('/');
        }
      } else {
        notification.error({
          message: response.getErrorMessage(
            "Your email isn't exist in our system. Contact the admin for more details"
          ),
        });
        yield put({
          type: 'notification',
          payload: false,
        });
      }
    },
    *submitEmail({ payload: { email, successPath, failurePath } }, { call, put }) {
      const response = yield call(checkEmailExsits, email);
      if (response.isSuccess) {
        const { data } = response;
        if (data && data.isExist === true) {
          const user = {
            email,
            name: data.name,
          };
          yield setUser(user);
          yield put({
            type: 'saveCurrentUser',
            payload: user,
          });
          history.push(successPath || '/');
        } else {
          yield setUser({ email, name: data.name });
          yield put({
            type: 'saveCurrentUser',
            payload: {
              email,
              name: data.name,
            },
          });
          history.push(failurePath || '/');
        }
      } else {
        notification.error({
          message: response.getErrorMessage('Failed to check email'),
        });
      }
    },
    *login({ payload }, { call, put }) {
      const response = yield call(login, { email: payload.email, password: payload.password });
      if (response.isSuccess) {
        const { token, user } = response.data;
        yield setAuthority(user.userType);
        yield setToken(token);
        yield setUser(user);
        yield reloadAuthorized();
        yield put({ type: 'subscribeToOneSignal', payload: user });
        yield put({
          type: 'notification',
          payload: true,
        });
        window.location.href = getRedirect() || HOME_URL;
      } else {
        notification.error({
          message: response.getErrorMessage('Failed to login'),
        });
      }
    },
    *loginWithSmartToken({ payload }, { call, put }) {
      const response = yield call(loginWithSmartToken, { token: payload.token });
      if (response.isSuccess) {
        const { token, user } = response.data;
        yield setAuthority(user.userType);
        yield setToken(token);
        yield setUser(user);
        yield reloadAuthorized();
        yield put({ type: 'subscribeToOneSignal', payload: user });
        yield put({
          type: 'notification',
          payload: true,
        });
      } else {
        notification.error({
          message: response.getErrorMessage('Failed to login'),
        });
      }
      history.push('/');
    },
    *loginWithGoogle({ payload }, { call, put }) {
      const response = yield call(loginWithGoogle, { token: payload.token });
      if (response.isSuccess) {
        const { token, user } = response.data;
        yield setAuthority(user.userType);
        yield setToken(token);
        yield setUser(user);
        yield reloadAuthorized();
        yield put({ type: 'subscribeToOneSignal', payload: user });
        window.location.href = getRedirect() || HOME_URL;
      } else {
        notification.error({
          message: response.getErrorMessage('Fail to login with Google'),
        });
      }
      history.push('/');
    },
    *loginWithApple({ payload }, { call, put }) {
      const response = yield call(loginWithApple, { token: payload.token, email: payload.email });
      if (response.isSuccess) {
        const { token, user } = response.data;
        yield setAuthority(user.userType);
        yield setToken(token);
        yield setUser(user);
        yield reloadAuthorized();
        yield put({ type: 'subscribeToOneSignal', payload: user });
        window.location.href = getRedirect() || HOME_URL;
      } else {
        notification.error({
          message: response.getErrorMessage('Fail to login with Apple'),
        });
      }
    },
    *loginAnonymous({ payload }, { call }) {
      const { slug, history, lowerCaseAnonymous, endPath } = payload;
      const response = yield call(getAnonymousToken, slug);

      if (response.isSuccess) {
        const { token } = response.data;
        yield setAuthority('');
        yield setToken(token);
        yield setUser('');
        const generalInfo = yield call(fetchGeneralInfoSponsor, slug);

        if (generalInfo.isSuccess) {
          history.replace(`/do-pulse/${slug}/do-pulse-with/${lowerCaseAnonymous}/${endPath}`);
        }
      } else {
        notification.error({
          message: response.getErrorMessage('Something went wrongs'),
        });
      }
    },
    *handleNoAnonymous({ payload }, { call }) {
      const { slug, path, history } = payload;

      const response = yield call(fetchGeneralInfoSponsor, slug);

      if (response.isSuccess) {
        const { data } = response;

        if (data?.statusOfSurvey === 'Invited') {
          history.replace(`${path}/no-anonymous/invited`);
          return;
        }

        if (data?.pulseType === PULSE_TYPE.REGISTER) {
          history.replace(`${path}/no-anonymous/register`);
          return;
        }

        if (
          data?.allowAnonymous &&
          data?.pulseStage === ConfigSite.STATUS.RUNNING &&
          data?.callToAction === ConfigSite.CALL_TO_ACTION.DO_PULSE
        ) {
          history.push(`${path}?allowAnonymous=${true}`);
          return;
        }

        history.replace(`${path}/no-anonymous/welcome`);
      } else {
        notification.error({
          message: response.getErrorMessage('Something went wrongs'),
        });
      }
    },
  },
  reducers: {
    notification(state, action) {
      return {
        ...state,
        authStatus: action.payload,
      };
    },
    getRedirectPut(state, action) {
      return {
        ...state,
        redirect: action.payload,
      };
    },
    jwt(state, action) {
      return { ...state, jwt: action.payload };
    },
    saveCurrentUser(state, action) {
      return { ...state, currentUser: action.payload || {} };
    },
    errorGenerateAnonyName(state, action) {
      return { ...state, resGenAnon: action.payload.ok };
    },
    updateSubscribe(state, { payload: isSubscribe }) {
      return { ...state, isSubscribe };
    },
  },
};
export default UserModel;
