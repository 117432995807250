import { notification } from 'antd';
import messageSuccess from '@/components/CustomizedMessage/success';
import { addCollector as addCollectorService } from '@/services/collection';

function* addCollector({ payload }, { call, put, select }) {
  const id = yield select(state => state.collection.data.id || null);
  if (!id) {
    notification.error({ message: 'Failed to collector into collection' });
  } else {
    const { data, formRef } = payload;
    const body = { collectors: data };
    const res = yield call(addCollectorService, id, body);
    if (formRef) {
      formRef.current.resetFields();
    }

    if (!res.isSuccess) {
      notification.error({
        message: res.getErrorMessage('Failed to add collector into collection'),
      });
    } else {
      messageSuccess('You have successfully added collector into collection');
      yield put({
        type: 'details',
        payload: id,
      });
    }
  }
}
export default addCollector;
