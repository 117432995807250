import { delay } from 'dva/saga';
import { notification } from 'antd';

import { serviceResponsePulseSponsorsRequest } from '@/services/singlePulse';
import ConfigSite from '@/../config/configSite';
import messageSuccess from '@/components/CustomizedMessage/success';

function* responsePulseSponsorsRequest({ payload }: any, { call, take, race }: any) {
  try {
    const { pulseId, sponsorIds, response, callback } = payload;
    const messageAction = response === ConfigSite.SPONSOR_STAGE.ACCEPTED ? 'Accepted' : 'Rejected';

    const { undo, task } = yield race({
      undo: take((action: any) => action.type === `pulse/undoResponsePulseSponsorsRequest`),
      task: delay(ConfigSite.TIME_FOR_UNDO),
    });

    if (undo) {
      messageSuccess(`You have successfully undo with ${messageAction} collector`);
      return;
    }

    if (task) {
      const { meta } = yield call(serviceResponsePulseSponsorsRequest, pulseId, sponsorIds, response);

      if (meta?.ok) {
        if (typeof callback === 'function') {
          callback();
        }

        return notification.success({ message: `${messageAction} sponsors successfully` });
      }

      notification.error({
        message: 'Failed to delete pulse sponsors',
        description: meta?.message,
      });
    }
  } catch (error) {
    notification.error({ message: 'Something went wrong' });
  }
  return true;
}

export default responsePulseSponsorsRequest;
