import { notification } from 'antd';
import { getFeelingQuestionAdmin as getFeelingQuestionAdminService } from '@/services/survey';

function* getFeelingQuestionAdmin({ payload }, { call, put }) {
  try {
    const { pulseId, callback } = payload;

    const result = yield call(getFeelingQuestionAdminService, pulseId);
    if (result.isSuccess) {
      yield put({
        type: 'appendFeeling',
        payload: result.data,
      });

      if (typeof callback === 'function') {
        callback();
      }

    } else {
      notification.error({ message: result.getErrorMessage('Failed to get feeling question') });
    }
  } catch (error) {
    console.error(error);
  }
}
export default getFeelingQuestionAdmin;
