import { query } from '@/services/collection';

function* fetch({ payload }, { call, put }) {
  const response = yield call(query, payload);
  if (response.isSuccess) {
    yield put({
      type: 'append',
      payload: {
        meta: response.meta,
        data: response.data,
      },
    });
  }
}

export default fetch;
