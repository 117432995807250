import React from 'react';
import { Spin } from 'antd';
import style from './style.less';

const PageLoading = () => (
  <div className={`${style.main}`}>
    <Spin size="large" />
  </div>
);

export default PageLoading;
