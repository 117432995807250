import { notification } from 'antd';
import { getFeelingQuestion as getFeelingQuestionService } from '@/services/survey';

function* getFeelingQuestion(action, { call, put }) {
  try {
    const result = yield call(getFeelingQuestionService, action.payload);
    if (result.isSuccess) {
      yield put({
        type: 'appendFeeling',
        payload: result.data,
      });
    } else {
      notification.error({ message: result.getErrorMessage('Failed to get feeling question') });
    }
  } catch (error) {
    console.error(error);
  }
}
export default getFeelingQuestion;
