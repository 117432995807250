export const GET_SINGLE_PULSE = '/pulses';

export const EXPORT_PULSES = '/pulses/exportFile';

export const GET_SINGLE_PULSE_DETAILS = '/pulses/:id';

export const GET_SINGLE_PULSE_CHANGE_STATUS = '/pulses/:id/status';

export const PULSE_SPONSORS = '/pulses/:id/sponsors';

export const PULSE_SPONSOR_CONFIRM = '/pulses/:id/sponsors/confirm';

export const PULSE_COLLECTORS = '/pulses/:id/collectors';
