import { Option, Question } from '@/types/question';
import { cloneDeep } from 'lodash';
import { QUESTION_TYPE } from './constants';

export const formatPulseTemplateData = (questions: Question[]) => {
  let feelingQuestion: Question | null = null;
  let questionList: Question[] = [];
  let availableBranches: string[] = [];
  let questionBeforeBranching: Question[] = [];

  if (!questions || questions.length === 0) {
    return {
      feelingQuestion,
      questionList,
      availableBranches,
      questionBeforeBranching,
    };
  }

  const questionsWithId = questions.map((question, index) => {
    return {
      ...question,
      questionId: index+1,
    };
  });

  questionList = cloneDeep(questionsWithId);

  const feelingIndex = questionsWithId.findIndex(
    question => question.questionType === QUESTION_TYPE.FEELING
  );

  if (feelingIndex > -1) {
    feelingQuestion = questionsWithId[feelingIndex];
    questionList.splice(feelingIndex, 1);
  }

  const branchingIndex = questionsWithId.findIndex(
    question => question.questionType === QUESTION_TYPE.BRANCHING
  );
  if (branchingIndex > -1) {
    availableBranches =
    questionsWithId[branchingIndex].pulselyOption?.map((option: Option) => option.optionTitle) || [];
    questionBeforeBranching = questionsWithId.slice(0, branchingIndex);
  }

  return {
    feelingQuestion,
    questionList,
    availableBranches,
    questionBeforeBranching,
  };
};
