import { stringify } from 'qs';
import request from '@/utils/request';
import ConfigSite from '../../config/configSite';

export async function query(params) {
  if (!params) {
    params = {
      page: 1,
      limit: ConfigSite.LIMIT,
    };
  }
  return request(`/sponsor-list?${stringify(params)}`);
}

export async function create(params) {
  return request('/sponsor-list', {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

export async function addSponsor(id, sponsors) {
  return request(`/sponsor-list/${id}/sponsors`, {
    method: 'POST',
    body: JSON.stringify(sponsors),
  });
}

export async function updateStatus(slug, status) {
  return request.put(`/sponsor-list/${slug}/status/${status}`);
}

export async function exportSponsor(params) {
  return request(`/sponsor-list/${params}/export`);
}

export async function getExportFile(params) {
  return request(`/sponsor-list/${params}/exportFile`, { responseType: 'blob' });
}

export async function duplicateSponsor(sponsorListId) {
  return request.post(`/sponsor-list/${sponsorListId}/_duplicate`);
}

export async function fetchGeneralInfo(params) {
  return request(`/sponsor-list/${params}/generalInfo`);
}

export async function fetchSponsorListDetails(id) {
  return request(`/sponsor-list/${id}/detail`);
}

export async function fetchGeneralInfoAdmin(params) {
  return request(`/admin/sponsor-list/${params}/generalInfo`);
}

export async function fetchSponsorLink(slug) {
  return request(`/sponsor-list/${slug}/generate`);
}

export async function getAllQuestion(params) {
  return request(`/sponsor-list/${params}`);
}

export async function getModifyUser({ slug, params }) {
  return request(`/sponsor-list/${slug}/member/modify?${stringify(params)}`);
}

export async function modifyMember(params) {
  return request(`/sponsor-list/member`, {
    method: 'PUT',
    body: JSON.stringify(params),
  });
}

export async function getSponsorSpecificMember(params) {
  if (params.sponsorStatus) {
    return request(`/sponsor-list/member/sponsor-list/?status=${params.sponsorStatus}`);
  }
  return request('/sponsor-list/member/sponsor-list/?status=all');
}

export async function updateEmailList(slug, params) {
  return request(`/sponsor-list/${slug}/updateSponsorEmail`, {
    method: 'PUT',
    body: JSON.stringify(params),
  });
}

export async function searchSponsor(payload) {
  const { collectionId, queryString } = payload;
  return request(`/collections/${collectionId}/sponsors/_search?query=${queryString}`);
}

/**
 *
 * @param {number} collectionId
 * @param {string} sponsorId
 * @returns
 */

export async function removeSponsor(collectionId, sponsors) {
  return request(`/collections/${collectionId}/sponsors`, {
    method: 'DELETE',
    body: JSON.stringify({ sponsorsList: sponsors }),
  });
}
