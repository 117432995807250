import effects from './effects';
import reducers from './reducers';

const initialState = {
  listResource: {
    list: [],
    pagination: {
      limit: 0,
      offset: 0,
      total: 0,
    },
  },
  currentResource: {
    resourceId: -1,
    resourceName: '',
    payload: [],
  },
  currentPulseTemplate: {
    feelingQuestion: null,
    questionList: [],
    availableBranches: [],
    questionBeforeBranching: [],
  },
};
const ResourceModel = {
  namespace: 'resource',
  state: initialState,
  effects,
  reducers,
};

export default ResourceModel;
