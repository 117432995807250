// import { message } from 'antd';
import * as Sentry from '@sentry/browser';
// import { useIntl } from 'umi';
import ReactGA from 'react-ga4';

import '@ant-design/compatible/assets/index.css';

import defaultSettings from '../config/defaultSettings';

const { pwa } = defaultSettings; // if pwa is true

Sentry.init({ dsn: SENTRY_DSN });
if (pwa) {
  // Notify user if offline now
  window.addEventListener('sw.offline', () => {
    // message.warning(
    //   useIntl().formatMessage({
    //     id: 'app.pwa.offline',
    //   })
    // );
  }); // Pop up a prompt on the page asking the user if they want to use the latest version
} else if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then(registration => {
    registration.unregister();
  });
}

const { NODE_ENV } = process.env;
if (NODE_ENV === 'production' && GOOGLE_ANALYTICS) {
  ReactGA.initialize(GOOGLE_ANALYTICS);
}
