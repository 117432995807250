import { getAllQuestion } from '@/services/survey';
import { notification } from 'antd';

interface IQuestionPreview {
  payload: { slug: string };
}

function* generatorQuestionPreview({ payload }: IQuestionPreview, { call, put }: any) {
  const { slug } = payload;
  try {
    const { meta, data } = yield call(getAllQuestion, slug);
    const { questions } = data;
    const formattedList = questions.map((question: any) => {
      const q = { ...question, pulselyOption: question?.options };
      delete q.options;
      return q;
    });
    if (meta.ok) {
      yield put({ type: 'saveDataPreview', payload: { questions: formattedList } });
    } else {
      notification.error({ message: 'something went wrong' });
    }
  } catch (error) {
    notification.error({ message: 'something went wrong' });
  }
}

export default generatorQuestionPreview;
