import { notification } from 'antd';
import { serviceAddPulseCollectors, serviceAddPulseSponsors } from '@/services/singlePulse';
import { getPulseCollectors, getPulseSponsors } from '../actions';

type Member = {
  name: string;
  email: string;
};

interface Payload {
  pulseId: string;
  collectors: Member[];
  callback?: () => void;
}

function* addPulseCollectors({ payload }: { payload: Payload }, { call, put }: any) {
  try {
    const { pulseId, collectors, callback } = payload;

    const { meta } = yield call(serviceAddPulseCollectors, pulseId, collectors);

    if (meta?.ok) {
      yield put(getPulseCollectors(pulseId));

      notification.success({ message: 'Add collectors successfully' });

      if (typeof callback === 'function') {
        callback();
      }
      return;
    }
    notification.error({ message: 'Failed to add collectors', description: meta?.message });
  } catch (error) {
    notification.error({ message: 'Something went wrong' });
  }
  return true;
}

export default addPulseCollectors;
