import { getAvailableKeywords as getAvailableKeywordsService } from '@/services/question';

function* getAvailableKeywords({ payload }, { call, put }) {
  try {
    const response = yield call(getAvailableKeywordsService, payload);
    if (!response.isSuccess)
      return yield put({
        type: 'saveKeywords',
        payload: {},
      });

    const keywordsPayload = {
      questionId: payload?.questionId,
      keyword: response.data?.keywords,
    };

    yield put({
      type: 'saveKeywords',
      payload: keywordsPayload,
    });

    return true;
  } catch (error) {
    console.error(error);
  }
}

export default getAvailableKeywords;
