// @ts-nocheck
import { Component } from 'react';
import { ApplyPluginsType } from 'umi';
import dva from 'dva';
// @ts-ignore
import createLoading from '/opt/build/repo/node_modules/dva-loading/dist/index.esm.js';
import { plugin, history } from '../core/umiExports';
import ModelIndex0 from '/opt/build/repo/src/models/Collection/index.js';
import ModelCollector1 from '/opt/build/repo/src/models/collector.js';
import ModelCreatesSurveyModel2 from '/opt/build/repo/src/models/CreatesSurveyModel.js';
import ModelLivemode3 from '/opt/build/repo/src/models/livemode.js';
import ModelLogin4 from '/opt/build/repo/src/models/login.js';
import ModelMember5 from '/opt/build/repo/src/models/member.js';
import ModelIndex6 from '/opt/build/repo/src/models/Pulse/index.ts';
import ModelIndex7 from '/opt/build/repo/src/models/Question/index.ts';
import ModelQuestionBank8 from '/opt/build/repo/src/models/questionBank.js';
import ModelIndex9 from '/opt/build/repo/src/models/Resource/index.ts';
import ModelSponsor10 from '/opt/build/repo/src/models/sponsor.js';
import ModelSponsorList11 from '/opt/build/repo/src/models/sponsorList.js';
import ModelSummaryModel12 from '/opt/build/repo/src/models/SummaryModel.js';
import ModelSurvey13 from '/opt/build/repo/src/models/survey.js';
import ModelUserModel14 from '/opt/build/repo/src/models/UserModel.js';
import ModelModel15 from '/opt/build/repo/src/pages/HistorySurvey/model.js';
import dvaImmer, { enableES5, enableAllPlugins } from '/opt/build/repo/node_modules/dva-immer/dist/index.js';

let app:any = null;

export function _onCreate(options = {}) {
  const runtimeDva = plugin.applyPlugins({
    key: 'dva',
    type: ApplyPluginsType.modify,
    initialValue: {},
  });
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    // @ts-ignore
    ...(typeof window !== 'undefined' && window.g_useSSR ? { initialState: window.g_initialProps } : {}),
    ...(options || {}),
  });
  
  app.use(createLoading());
  app.use(dvaImmer());
  (runtimeDva.plugins || []).forEach((plugin:any) => {
    app.use(plugin);
  });
  app.model({ namespace: 'index', ...ModelIndex0 });
app.model({ namespace: 'collector', ...ModelCollector1 });
app.model({ namespace: 'CreatesSurveyModel', ...ModelCreatesSurveyModel2 });
app.model({ namespace: 'livemode', ...ModelLivemode3 });
app.model({ namespace: 'login', ...ModelLogin4 });
app.model({ namespace: 'member', ...ModelMember5 });
app.model({ namespace: 'index', ...ModelIndex6 });
app.model({ namespace: 'index', ...ModelIndex7 });
app.model({ namespace: 'questionBank', ...ModelQuestionBank8 });
app.model({ namespace: 'index', ...ModelIndex9 });
app.model({ namespace: 'sponsor', ...ModelSponsor10 });
app.model({ namespace: 'sponsorList', ...ModelSponsorList11 });
app.model({ namespace: 'SummaryModel', ...ModelSummaryModel12 });
app.model({ namespace: 'survey', ...ModelSurvey13 });
app.model({ namespace: 'UserModel', ...ModelUserModel14 });
app.model({ namespace: 'model', ...ModelModel15 });
  return app;
}

export function getApp() {
  return app;
}

/**
 * whether browser env
 * 
 * @returns boolean
 */
function isBrowser(): boolean {
  return typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
}

export class _DvaContainer extends Component {
  constructor(props: any) {
    super(props);
    // run only in client, avoid override server _onCreate()
    if (isBrowser()) {
      _onCreate()
    }
  }

  componentWillUnmount() {
    let app = getApp();
    app._models.forEach((model:any) => {
      app.unmodel(model.namespace);
    });
    app._models = [];
    try {
      // 释放 app，for gc
      // immer 场景 app 是 read-only 的，这里 try catch 一下
      app = null;
    } catch(e) {
      console.error(e);
    }
  }

  render() {
    let app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
