import { ANSWER_TYPE, PULSE_TYPE, QUESTION_TYPE } from "@/utils/constants";

export default function updateDefaultQuestion(state: QuestionModel.QuestionModelState, { payload }: { payload: any}) {
    const { pulseType, sponsors } = payload;
    const options = [];
    let questionType = QUESTION_TYPE.TEXT;
    if (pulseType === PULSE_TYPE.ASSESS) {
      options.push(
        ...sponsors.map((provider: any) => ({
          optionTitle: provider.user.username,
          isOptionText: 0,
          optionType: ANSWER_TYPE.TEXT,
        }))
      );
      questionType = QUESTION_TYPE.ASSESS;
    }
    return {
      ...state,
      defaultQuestion: {
        questionId: -10,
        questionTitle: '',
        questionType,
        questionDescription: '',
        pulselyOption: options,
        isRequired: 1,
      },
    };
  }