import { message } from 'antd';

let isSubscribing = false;

const subscribeByUserId = userId => {
  if (isSubscribing) {
    return Promise.resolve(false);
  }
  isSubscribing = true;
  try {
    const OneSignal = window.OneSignal || [];
    if (!userId) {
      throw Error('userId is null');
    }

    if (!OneSignal.initialized) {
      OneSignal.push(() => {
        OneSignal.init({
          appId: ONE_SIGNAL_APP_ID,
        });
      });
    }
    OneSignal.push(() => {
      OneSignal.setExternalUserId(`u_${userId}`);
    });
    // eslint-disable-next-line compat/compat
    return new Promise(resolve => {
      OneSignal.push(() => {
        OneSignal.setSubscription(true)
          .then(() => {
            isSubscribing = false;
            resolve(true);
          })
          .catch(err => {
            isSubscribing = false;
            console.error(err);
            resolve(false);
          });
      });
    });
  } catch (error) {
    console.error(error);
    message.error('Failed to subscribe to OneSignal');
    // eslint-disable-next-line compat/compat
    return Promise.resolve(false);
  }
};

const unsubscribe = () => {
  try {
    const OneSignal = window.OneSignal || [];
    OneSignal.push(() => {
      OneSignal.setSubscription(false);
    });
  } catch (error) {
    console.error(error);
    message.error('Failed to subscribe to OneSignal');
  }
};

export { subscribeByUserId, unsubscribe };
