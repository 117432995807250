import { notification } from 'antd';
import {
  getDataQuestionList,
  createNewQuestion,
  editQuestion,
  deleteItem,
} from '@/services/QuestionList';

const QuestionListModel = {
  namespace: 'questionbank',
  state: {
    questionList: [],
    totalItems: 0,
    isLoading: false,
    currentPage: 1,
    questionEdit: [],
    valueSearch: '',
  },
  effects: {
    *getQuestionList(action, { put, call }) {
      try {
        const res = yield call(getDataQuestionList, action.payload);
        if (res.isSuccess) {
          yield put({
            type: 'saveQuestionList',
            payload: res.data,
          });
        } else {
          notification.error({ message: res.meta.message });
        }
      } catch (error) {
        console.error(error);
      }
    },
    *createQuestion({ payload }, { call, put }) {
      const response = yield call(createNewQuestion, { data: payload.question });
      if (response.isSuccess) {
        notification.success({ message: 'Create question successfully' });
        payload.hideModal();
        payload.reset();
        try {
          const res = yield call(getDataQuestionList, { pageSize: 10, current: 1 });
          if (res.isSuccess) {
            yield put({
              type: 'saveQuestionList',
              payload: res.data,
            });
          } else {
            notification.error({ message: res.meta.message });
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        notification.error({ message: response.meta.message });
      }
    },
    *editQuestion({ payload }, { call, put }) {
      const response = yield call(editQuestion, { question: payload.question, id: payload.id });
      if (response.isSuccess) {
        notification.success({ message: 'Edit question successfully' });
        payload.hideModal();
        try {
          const res = yield call(getDataQuestionList, { pageSize: 10, current: 1 });
          if (res.isSuccess) {
            yield put({
              type: 'saveQuestionList',
              payload: res.data,
            });
          } else {
            notification.error({ message: res.meta.message });
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        notification.error({ message: response.meta.message });
      }
    },
    *deleteQuestion(action, { call }) {
      const response = yield call(deleteItem, action.payload);
      if (response.isSuccess) {
        notification.success({ message: 'Delete successfully' });
      } else {
        notification.error({ message: response.meta.message });
      }
    },
  },
  reducers: {
    saveQuestionDetail(state, action) {
      return {
        ...state,
        questionEdit: action.payload,
      };
    },
    saveValueSearch(state, action) {
      return {
        ...state,
        valueSearch: action.payload,
      };
    },
    saveQuestionList(state, action) {
      return {
        ...state,
        questionList: action.payload.questions,
        totalItems: action.payload.pagination.total,
        currentPage: action.payload.pagination.offset + 1,
      };
    },
    onIsLoading(state, action) {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    // changeStatusModal(state, action) {
    //   return {
    //     ...state,
    //     setStatusModal: action.payload,
    //   };
    // },
  },
};

export default QuestionListModel;
