export default function removeQuestion(state: QuestionModel.QuestionModelState, action: any) {
    const questionId = action?.payload?.questionId;
    const filterState = state?.list?.filter(item => item?.questionId !== questionId);
    const nextState = filterState?.map((item, index) => ({ ...item, order: index + 1 }));

    return {
      ...state,
      questions: nextState,
      list: nextState,
      generalInfo: action.payload.generalInfo,
    };
  }