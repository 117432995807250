import urlcat from 'urlcat';
import {
  GET_SINGLE_PULSE,
  GET_SINGLE_PULSE_DETAILS,
  GET_SINGLE_PULSE_CHANGE_STATUS,
  PULSE_SPONSOR,
  PULSE_SPONSORS,
  PULSE_COLLECTORS,
  PULSE_SPONSOR_CONFIRM,
  EXPORT_PULSES,
} from '../api-routes';
import { request } from '@/utils/request';

export const serviceGetPulses = params => {
  const endpoint = urlcat(GET_SINGLE_PULSE, { ...params });
  return request(endpoint, { method: 'GET' });
};

export const serviceCreatePulses = data => {
  const endpoint = urlcat(GET_SINGLE_PULSE);
  return request(endpoint, { method: 'POST', data });
};

export const servicePulseDetails = id => {
  const endpoint = urlcat(GET_SINGLE_PULSE_DETAILS, { id });
  return request(endpoint, { method: 'GET' });
};

export const serviceChangePulse = (id, status) => {
  const endpoint = urlcat(GET_SINGLE_PULSE_CHANGE_STATUS, { id, status });

  return request(endpoint, { method: 'PUT' });
};

export const serviceChangePulseConfig = (pulseId, config) => {
  const endpoint = urlcat(GET_SINGLE_PULSE_DETAILS, { id: pulseId });

  return request(endpoint, { method: 'PUT', body: JSON.stringify(config) });
};

export const serviceGetPulseSponsors = (pulseId, search) => {
  const endpoint = urlcat(PULSE_SPONSORS, { id: pulseId });

  return request(endpoint, {
    method: 'GET',
    params: {
      query: search,
    },
  });
};

export const serviceAddPulseSponsors = (pulseId, sponsors) => {
  const endpoint = urlcat(PULSE_SPONSORS, { id: pulseId });

  return request(endpoint, {
    method: 'PUT',
    body: JSON.stringify({
      sponsors,
    }),
  });
};

export const serviceDeletePulseSponsors = (pulseId, sponsorIds) => {
  const endpoint = urlcat(PULSE_SPONSORS, { id: pulseId });

  return request(endpoint, {
    method: 'DELETE',
    body: JSON.stringify({
      sponsorIds,
    }),
  });
};

export const serviceResponsePulseSponsorsRequest = (pulseId, sponsorIds, response) => {
  const endpoint = urlcat(PULSE_SPONSOR_CONFIRM, { id: pulseId });

  return request(endpoint, {
    method: 'PUT',
    body: JSON.stringify({
      sponsorIds,
      response,
    }),
  });
};

export const serviceGetPulseCollectors = (pulseId, search) => {
  const endpoint = urlcat(PULSE_COLLECTORS, { id: pulseId });

  return request(endpoint, {
    method: 'GET',
    params: {
      query: search,
    },
  });
};

export const serviceAddPulseCollectors = (pulseId, collectors) => {
  const endpoint = urlcat(PULSE_COLLECTORS, { id: pulseId });

  return request(endpoint, {
    method: 'PUT',
    body: JSON.stringify({
      collectors,
    }),
  });
};

export const serviceDeletePulseCollector = (pulseId, collectorId) => {
  const endpoint = urlcat(PULSE_COLLECTORS, { id: pulseId });

  return request(endpoint, {
    method: 'DELETE',
    body: JSON.stringify({
      collectorId,
    }),
  });
};

export const serviceExportPulses = (pulseIds) => {
  return request(EXPORT_PULSES, {
    method: 'POST',
    body: JSON.stringify({
      pulseIds,
    }),
    responseType: 'blob',
  });
};

