import latinize from 'latinize';
import ConfigSite from '@/../config/configSite';

const { USER_TYPE } = ConfigSite;

// use localStorage to store the authority info, which might be sent from server in actual project.
export function getAuthority(str) {
  // return localStorage.getItem('authority') || ['admin', 'user'];
  const authorityString = typeof str === 'undefined' ? localStorage.getItem('authority') : str; // authorityString could be admin, "admin", ["admin"]

  let authority;

  try {
    authority = JSON.parse(authorityString);
  } catch (e) {
    authority = authorityString;
  }

  if (typeof authority === 'string') {
    return [authority];
  } // preview.pro.ant.design only do not use in your production ; preview.pro.ant.design 专用环境变量，请不要在你的项目中使用它。

  if (!authority) {
    return [];
  }

  return authority;
}

export function setAuthority(userType) {
  let stored = [];
  let authority;
  switch (parseInt(userType, 10)) {
    case USER_TYPE.ADMIN:
      authority = 'admin';
      break;
    case USER_TYPE.USER:
      authority = 'user';
      break;
    case USER_TYPE.EXTERNAL:
      authority = 'externalUser';
      break;
    case USER_TYPE.GUEST:
      authority = 'guest';
      break;
    default:
      authority = '';
      break;
  }
  stored = JSON.parse(localStorage.getItem('authority'));
  if (stored || Array.isArray(stored)) {
    stored.push(authority);
    return localStorage.setItem('authority', JSON.stringify(stored));
  }
  const proAuthority = [authority];
  return localStorage.setItem('authority', JSON.stringify(proAuthority));
}

export function setToken(token) {
  return localStorage.setItem('token', token);
}

export function setRedirect(redirect) {
  return localStorage.setItem('redirect', redirect);
}

export function getRedirect() {
  return localStorage.getItem('redirect');
}

export function setAnonName(anonName) {
  return localStorage.setItem('anonName', anonName);
}

export function getToken() {
  return localStorage.getItem('token');
}

export function setUser(user = {}) {
  return localStorage.setItem('user', JSON.stringify({ ...user, name: latinize(user?.name) }));
}

export function getUser() {
  return localStorage.getItem('user') || '{}';
}

export function removeTokenAuthority() {
  localStorage.clear();
}
