import { notification } from 'antd';
import { history } from 'umi';
import {
  createResource,
  deleteResourceById,
  getResourceById,
  getResources,
  updateResourceById,
} from '@/services/resource';
import { ResourceType } from '@/utils/constants';

export default <ResourceModel.ResourceModelEffects>{
  *getResources({ payload }, { call, put }) {
    try {
      const { params, callback } = payload;
      const { meta, data } = yield call(getResources, params);

      if (!meta.ok) return notification.error({ message: 'Failed to get Resources' });

      const { pagination, listResources } = data;

      yield put({
        type: 'saveResourceList',
        payload: {
          pagination,
          listResources,
        },
      });

      callback?.();
    } catch (error) {
      console.error('getResources error:', error);
      notification.error({ message: 'Failed to get Resources' });
    }
  },
  *getCurrentResource({ payload }, { call, put }) {
    try {
      const { id, savePulseTemplate,callback } = payload;
      const { meta, data } = yield call(getResourceById, id);

      if (!meta.ok) return notification.error({ message: 'Failed to get Resource' });

      yield put({
        type: 'saveCurrentResource',
        payload: data,
      });

      if (savePulseTemplate) {
        yield put({
          type: 'saveCurrentPulseTemplate',
          payload: data?.payload,
        });
      }

      callback?.();
    } catch (error) {
      console.error('*updateResource ~ error:', error);
      notification.error({ message: 'Failed to get Resource' });
    }
  },
  *createResource({ payload }, { call }) {
    try {
      const { data, callback } = payload;

      const { meta, data: resData } = yield call(createResource, data);

      if (!meta.ok) return notification.error({ message: 'Failed to create Resource' });
      notification.success({ message: 'Create Resource successfully' });
      callback?.();
      // Should not use this here, but i still not find out a better solution
      if (data?.resourceType === ResourceType.PULSES ) {
        if (!resData?.resourceId) {
          notification.error({ message: 'Failed to redirect to pulse template page' });
        }
        history.push(`/admin/library/pulse-template/${resData.resourceId}`);
      }
    } catch (error) {
      console.error('createResource error:', error);
      notification.error({ message: 'Failed to create Resource' });
    }
  },
  *updateResourceById({ payload }, { call }) {
    try {
      const { payload: data, id, callback } = payload;

      const { meta } = yield call(updateResourceById, data, id);

      if (!meta.ok) return notification.error({ message: 'Failed to update Resource' });
      notification.success({ message: 'Update Resource successfully' });
      callback?.();
    } catch (error) {
      console.error('updateResource error:', error);
      notification.error({ message: 'Failed to update Resource' });
    }
  },
  *deleteResourceById({ payload }, { call }) {
    try {
      const { id, callback } = payload;

      if (!id || id < 0) return notification.error({ message: 'Failed to delete Resource' });

      const { meta } = yield call(deleteResourceById, id);

      if (!meta.ok) return notification.error({ message: 'Failed to delete Resource' });

      notification.success({ message: 'Delete Resource successfully' });
      callback?.();
    } catch (error) {
      console.error('deleteResource error:', error);
      notification.error({ message: 'Failed to delete Resource' });
    }
  },
};
