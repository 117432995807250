import request from '@/utils/request';

export async function queryDetail(pulseId) {
  return request(`/pulses/${pulseId}/questions`);
}

export async function addQuestion(pulseId, question) {
  return request(`/pulses/${pulseId}/questions`, {
    method: 'POST',
    body: JSON.stringify(question),
  });
}

export async function addQuestions(pulseId, questions) {
  return request(`/pulses/${pulseId}/questions/bulk`, {
    method: 'POST',
    body: JSON.stringify({ questions }),
  });
}

export async function deleteQuestion(pulseId, questionId) {
  return request(`/pulses/${pulseId}/questions/${questionId}`, {
    method: 'DELETE',
  });
}

export async function updateQuestion(pulseId, questionId, question, options) {
  return request(`/pulses/${pulseId}/questions/${questionId}`, {
    method: 'PUT',
    body: JSON.stringify({ ...question, options }),
  });
}

export async function reOrderQuestion(questionId, order, pulseId) {
  return request(`/pulses/${pulseId}/questions/${questionId}/reorder`, {
    method: 'PUT',
    body: JSON.stringify({ newIndex: order }),
  });
}

export function getAvailableBranches(payload) {
  return request(`/pulses/${payload}/available-branches`);
}

export function getAvailableKeywords({ slug, questionId, example }) {
  return request(`/sponsors/pulses/${slug}/questions/${questionId}/suggestion?keyword=${example}`);
}
