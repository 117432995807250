import { notification } from 'antd';
import { updateCollection } from '@/services/collection';
import ConfigSite from '../../../config/configSite';
import messageSuccess from '@/components/CustomizedMessage/success';

function* update({ payload }, { call, put, select }) {
  const { closeModal, collectionId, body } = payload;
  try {
    const {
      pagination: { current },
    } = yield select(({ collection }) => collection.collections);

    const { meta } = yield call(updateCollection, collectionId, body);

    if (!meta.ok) return notification.error({ message: 'Failed to update collection title' });

    yield put({
      type: 'fetch',
      payload: {
        offset: (current - 1) * ConfigSite.LIMIT,
        limit: ConfigSite.LIMIT,
        order: 'DESC',
        status: 'active',
      },
    });
    messageSuccess('You have successfully update');
    return closeModal();
  } catch (error) {
    return notification.error({ message: 'Failed to update collection title' });
  }
}

export default update;
