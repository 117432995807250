const MODEL = 'pulse';

const PULSE_ACTION_TYPE = {
  // Effects
  CHANGE_PULSE_STATUS: `${MODEL}/changePulseStatus`,
  CREATE_PULSE: `${MODEL}/createPulse`,
  GET_PULSE: `${MODEL}/getPulse`,
  GET_PULSES: `${MODEL}/getPulses`,
  UPDATE_PULSE_CONFIG: `${MODEL}/updatePulseConfig`,
  GET_PULSE_SPONSORS: `${MODEL}/getPulseSponsors`,
  GET_PULSE_COLLECTORS: `${MODEL}/getPulseCollectors`,
  DELETE_PULSE_SPONSORS: `${MODEL}/deletePulseSponsors`,
  UNDO_DELETE_PULSE_SPONSORS: `${MODEL}/undoDeletePulseSponsors`,
  ADD_PULSE_SPONSORS: `${MODEL}/addPulseSponsors`,
  RESPONSE_PULSE_SPONSORS_REQUEST: `${MODEL}/responsePulseSponsorsRequest`,
  UNDO_RESPONSE_PULSE_SPONSORS_REQUEST: `${MODEL}/undoResponsePulseSponsorsRequest`,
  ADD_PULSE_COLLECTORS: `${MODEL}/addPulseCollectors`,
  DELETE_PULSE_COLLECTOR: `${MODEL}/deletePulseCollector`,
  UNDO_DELETE_PULSE_COLLECTOR: `${MODEL}/undoDeletePulseCollector`,
  EXPORT_PULSES: `${MODEL}/exportPulses`,

  // Reducers
  SAVE_PULSE_SPONSORS: `${MODEL}/savePulseSponsors`,
  SAVE_PULSE_COLLECTORS: `${MODEL}/savePulseCollectors`,
  SAVE_PULSES: `${MODEL}/savePulses`,
};

export default PULSE_ACTION_TYPE;
