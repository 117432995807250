import { getFeelingQuestion } from '@/services/survey';
import { notification } from 'antd';

interface IGetFeelingQuestion {
  payload: { slug: string };
}

function* generatorFeelingQuestion({ payload }: IGetFeelingQuestion, { call, put }: any) {
  const { slug } = payload;
  try {
    const { meta, data } = yield call(getFeelingQuestion, slug);
    if (meta.ok) {
      yield put({
        type: 'saveDataPreview',
        payload: { feelingQuestion: data?.questionId ? data : {} },
      });
    } else {
      notification.error({ message: 'something went wrong' });
    }
  } catch (error) {
    notification.error({ message: 'something went wrong' });
  }
}

export default generatorFeelingQuestion;
