/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */
import ReactGA from 'react-ga4';

export function onRouteChange({ location, routes, action }) {
  ReactGA.send({
    hitType: 'pageview',
    page: location.pathname + location.search,
    title: document.title,
  });
  window.scrollTo({
    top: 0,
    left: 0,
  });
}
