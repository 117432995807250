import { notification } from 'antd';
import { delay } from 'dva/saga';
import messageSuccess from '@/components/CustomizedMessage/success';
import configSite from '@/../config/configSite';
import {
  create,
  // duplicateSponsor,
  query,
  // updateSponsor,
  // addSponsor,
  removeCollector,
} from '@/services/collector';

export default {
  namespace: 'collector',

  state: {
    collectors: [],
    data: {},
    tempCollector: {},
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const response = yield call(query, payload);
      if (response.isSuccess) {
        yield put({
          type: 'append',
          payload: {
            meta: response.meta,
            data: response.data,
          },
        });
      }
    },
    *create({ payload }, { call }) {
      const body = { ...payload, collectors: [] };
      const res = yield call(create, body);
      if (!res.isSuccess) {
        notification.error({ message: res.getErrorMessage('Failed to create collector list') });
      }
    },

    *remove({ payload }, { call, put, select, race, take }) {
      const { collectionId, collectorId } = payload;
      const { undo, task } = yield race({
        undo: take(action => action.type === `collector/undoRemove${collectorId}`),
        task: delay(configSite.TIME_FOR_UNDO),
      });

      if (undo) {
        // revert thread to previous state
        const { tempCollector } = yield select(model => model.collector);
        yield put({
          type: 'appendCollector',
          payload: tempCollector,
        });
        messageSuccess(`You have successfully undo with removing collector`);
        return;
      }
      if (task) {
        // make the API call to apply the changes remotely
        const res = yield call(removeCollector, collectionId, collectorId);
        if (res.isSuccess) {
          yield put({
            type: 'deleteCollector',
            payload: res.data,
          });
        } else {
          notification.error({
            message: res.getErrorMessage() || 'Can not remove collector',
          });
        }
      }
    },
  },
  reducers: {
    append(state, action) {
      const {
        payload: { data },
      } = action;
      const standardizedCollectors = data.map(item => {
        const tempCollector = { ...item, ...item?.user };
        delete tempCollector.user;
        return tempCollector;
      });
      return {
        ...state,
        collectors: standardizedCollectors || [],
      };
    },
    appendDetails(state, action) {
      const {
        payload: { data, meta },
      } = action;
      return {
        ...state,
        data: data || [],
        status: meta.ok,
      };
    },
    appendDuplicateStatus(state, action) {
      return {
        ...state,
        statusDuplicate: action.payload,
      };
    },
    saveAnswers(state, action) {
      return {
        ...state,
        answers: action.payload.answers,
      };
    },

    appendFeeling(state, action) {
      return {
        ...state,
        feeling: action.payload,
      };
    },

    appendSlug(state, action) {
      return {
        ...state,
        dataSlug: action.payload,
      };
    },

    appendWelcome(state, action) {
      return {
        ...state,
        welcome: action.payload,
      };
    },
    appendSubmit(state, action) {
      return {
        ...state,
        trash: true,
        submit: action,
      };
    },

    appendPreviewData(state, action) {
      return {
        ...state,
        previewData: action.payload,
      };
    },
    appendExport(state, action) {
      return {
        ...state,
        dataExport: action.payload,
      };
    },

    appendGeneralInfo(state, action) {
      const { generalInfo } = state;
      return {
        ...state,
        generalInfo: { ...generalInfo, ...action.payload },
      };
    },

    appendSponsorLink(state, action) {
      return {
        ...state,
        collectorLink: action.payload,
      };
    },

    appendQuestion(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },

    appendModifyUser(state, action) {
      return {
        ...state,
        dataModify: action.payload,
      };
    },
    appendSpecificList(state, action) {
      return {
        ...state,
        listSponsorSpecific: action.payload,
      };
    },
    appendPrivacy(state, action) {
      return {
        ...state,
        privacyChecked: action.payload,
      };
    },
    appendEmail(state, action) {
      return {
        ...state,
        listEmail: action.payload,
      };
    },
    setUnsaved(state, { payload }) {
      return {
        ...state,
        unsaved: payload.unsaved,
      };
    },
    toggleRemindCheckInModal(state, action) {
      return {
        ...state,
        remindCheckIn: action.payload,
      };
    },
    deleteCollector(state, { payload }) {
      const newCollectors = [...state.collectors].filter(item => item.userId !== payload.userId);
      return {
        ...state,
        collectors: newCollectors,
      };
    },
    saveTempCollector(state, { payload }) {
      return {
        ...state,
        tempCollector: payload,
      };
    },
    appendCollector(state, { payload }) {
      return {
        ...state,
        collectors: [...state.collectors, payload],
      };
    },
  },
};
