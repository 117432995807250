import { notification } from 'antd';
import { serviceAddPulseSponsors } from '@/services/singlePulse';
import { getPulseSponsors } from '../actions';

type Member = {
  name: string;
  email: string;
};

interface Payload {
  pulseId: string;
  sponsors: Member[];
  callback?: () => void;
}

function* addPulseSponsors({ payload }: { payload: Payload }, { call, put }: any) {
  try {
    const { pulseId, sponsors, callback } = payload;

    const { meta } = yield call(serviceAddPulseSponsors, pulseId, sponsors);

    if (meta?.ok) {
      yield put(getPulseSponsors(pulseId));

      notification.success({ message: 'Add sponsors successfully' });

      if (typeof callback === 'function') {
        callback();
      }
      return;
    }
    notification.error({ message: 'Failed to add sponsors', description: meta?.message });
  } catch (error) {
    notification.error({ message: 'Something went wrong' });
  }
  return true;
}

export default addPulseSponsors;
