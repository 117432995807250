import { notification } from 'antd';
import { serviceGetPulses } from '@/services/singlePulse';

function* getPulses({ payload }: any, { call, put }: any) {
  try {
    const { params, callback } = payload;
    params.q = params.title;
    delete params.title;
    const { meta, data } = yield call(serviceGetPulses, params);
    if (meta?.ok) {
      yield put({
        type: 'savePulses',
        payload: { pulses: data?.data, pagination: data?.pagination },
      });
      if (typeof callback === 'function') {
        callback();
      }
      return;
    }

    notification.error({ message: 'Failed to get list pulses', description: meta?.message });
  } catch (error) {
    notification.error({ message: 'Something went wrong' });
  }
  return true;
}

export default getPulses;
