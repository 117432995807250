import { notification } from 'antd';
import { addQuestion, queryDetail } from '@/services/question';
import ConfigSite from '@/../config/configSite';

const { QUESTION_TYPE } = ConfigSite;

function* create({ payload }, { call, put }) {
  const { question, pulseId, callback, order } = payload;
  try {
    const response = yield call(addQuestion, pulseId, question);
    if (!response.isSuccess) {
      return notification.error({
        message: 'Failed to add question',
        description: response?.meta?.message,
      });
    }

    if (question.questionType === QUESTION_TYPE.BRANCHING) {
      yield put({
        type: 'getAvailableBranches',
        payload: pulseId,
      });
    }

    notification.success({ message: 'Create question successfully' });

    if (typeof callback === 'function') {
      callback();
    }

    return true;
  } catch (error) {
    console.error(error);
  }
}

export default create;
