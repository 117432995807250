export default function clearPulselyOptionOfQuestion(state: QuestionModel.QuestionModelState, { payload }: { payload: any}) {
    const { list } = state;

    const newList = list.map(question => {
      if (question.questionId === payload) {
        question.pulselyOption = [];
      }
      return question;
    });

    return {
      ...state,
      list: newList,
    };
  }