import { notification } from 'antd';
import { serviceChangePulseConfig } from '@/services/singlePulse';

interface Payload {
  pulseId: string | number;
  config: any;
  callback?: () => void;
}

function* updatePulseConfig({ payload }: { payload: Payload }, { call, put }: any) {
  const { pulseId, config, callback } = payload;
  try {
    const { meta } = yield call(serviceChangePulseConfig, pulseId, config);

    if (meta?.ok) {
      if (typeof callback === 'function') {
        callback();
      }
      return notification.success({ message: 'Save config successfully' });
    }

    return notification.error({ message: 'Failed to save config', description: meta?.message });
  } catch (error) {
    notification.error({ message: 'Something went wrong' });
    console.error(error);
  }
  return true;
}

export default updatePulseConfig;
