import { Question } from '@/types/question';
import { produce } from 'immer';
import { isNumber } from 'lodash';

export default function updateQuestion(state: QuestionModel.QuestionModelState, action: any) {
    const body = action?.payload?.body;
    const questionId = action?.payload?.questionId;
    const order = action?.payload?.order;

    const nextState = produce(state?.list || [], draft => {
      if (action?.payload?.isFeeling) {
        draft.push({ ...body[0], order: body.length });
        return;
      }

      if (!isNumber(order)) {
        draft.push(body[body.length - 1]);
        return;
      }
      if (questionId) {
        const question = body.find((item: Question) => item?.questionId === questionId);
        draft[order - 1] = { ...question, order };
      } else {
        draft[order - 1] = { ...body[body?.length - 1], order };
      }
    });
    return {
      ...state,
      questions: nextState,
      list: nextState,
      generalInfo: action.payload.generalInfo,
    };
  }