import { stringify } from 'qs';
import request from '@/utils/request';
import ConfigSite from '../../config/configSite';

export function query(id, params) {
  if (!params) {
    params = {
      page: 1,
      limit: ConfigSite.LIMIT,
    };
  }
  return request(`/collection/${id}/pulse?${stringify(params)}`);
}

export function replyInvitation(payload) {
  const { pulseId, response } = payload;
  return request(`/sponsors/pulses/${pulseId}/reply?response=${response}`, {
    method: 'PUT',
  });
}

export function create(collectionID, payload) {
  return request(`/pulses`, {
    method: 'POST',
    body: JSON.stringify({
      ...payload,
    }),
  });
}

// TODO: ask backend to change `setting` to `settings`
export function updatePulseSettings(slug, payload) {
  return request.put(`/survey/${slug}/update-setting`, { body: JSON.stringify(payload) });
}

export function updateSurvey(slug, payload) {
  return request(`/survey/${slug}/update`, {
    method: 'PUT',
    body: JSON.stringify(payload),
  });
}

export async function updateStatus(pulseId, status) {
  return request.put(`/pulses/${pulseId}/status`, {
    params: {
      status,
    },
  });
}

export function updateSurveySlug(slug, params) {
  return request(`/survey/${slug}/updateSlug`, {
    method: 'PUT',
    body: JSON.stringify(params),
  });
}

export function welcomeSurvey(params) {
  return request(`/survey/${params}/welcome`);
}

export function submitSurvey(code, payload) {
  return request(`/sponsors/${code}/submit`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

export function exportSurvey(params) {
  return request(`/survey/${params}/export`);
}

export function getExportFile(params) {
  return request(`/survey/${params}/exportFile`, { responseType: 'blob' });
}

export function duplicateSurvey(params) {
  return request(`/survey/${params}/duplicate`);
}

export function getFeelingQuestion(params) {
  return request(`/sponsors/pulses/${params}/feeling`);
}

export function getFeelingQuestionAdmin(params) {
  return request(`/pulses/${params}/feeling`);
}

export function submitFeeling(params) {
  return request(`/survey/feeling`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

export function convertCode(params) {
  return request(`/survey/code/${params.code}`);
}

export function fetchGeneralInfo(params) {
  return request(`/pulses/${params}`);
}

export function fetchGeneralInfoSponsor(payload, isPreview = 0) {
  return request(`/sponsors/pulses/${payload}?isPreview=${isPreview}`);
}

export function fetchGeneralInfoAdmin(params) {
  return request(`/survey/${params}/generalInfo`);
}

export function fetchSurveyLink(pulseId) {
  return request(`/pulses/${pulseId}/generate`);
}

export function getAllQuestion(params) {
  return request(`/sponsors/pulses/${params}/questions`);
}

export function getModifyUser({ slug, params }) {
  return request(`/survey/${slug}/member/modify?${stringify(params)}`);
}

export function modifyMember(params) {
  return request(`/survey/member`, {
    method: 'PUT',
    body: JSON.stringify(params),
  });
}

export function getSurveySpecificMember() {
  return request(`/sponsors/pulses`);
}

export function getGuestMail(params) {
  return request(`/survey/${params}/guest`);
}

export function modifyGuestMail(params) {
  return request(`/survey/guest`, {
    method: 'PUT',
    body: JSON.stringify(params),
  });
}

export function checkPrivacy(params) {
  return request(`/pulses/${params}/key/check`, {
    method: 'GET',
  });
}

export function updateEmailList(slug, params) {
  return request(`/survey/${slug}/updateSurveyEmail`, {
    method: 'PUT',
    body: JSON.stringify(params),
  });
}

export function checkIn(params) {
  return request(`/sponsors/survey/checkIn`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

export function createTemplate(slug) {
  return request(`/survey/${slug}/add-template`, {
    method: 'POST',
  });
}

export function editable(slug, updatedAt) {
  return request(`/survey/${slug}/editable/${updatedAt}`);
}

export function getExportFileExample() {
  return request('/admin/file/example-excel-member-list', { responseType: 'blob' });
}

export function cancelRequest(payload) {
  return request(`/sponsors/pulses/${payload}/cancel`, {
    method: 'PUT',
  });
}

export function getHistoryPulse(payload) {
  return request(`/sponsors/pulses/history`, {
    params: payload,
  });
}

export function doCheckInPulse(payload) {
  return request(`/sponsors/pulses/${payload}/checkin`, {
    method: 'GET',
  });
}

export function fetchHistory(code) {
  return request(`/sponsors/pulses/${code}/completed`);
}
