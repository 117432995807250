import { notification } from 'antd';
import { serviceExportPulses } from '@/services/singlePulse';

function* exportPulses({ payload }: any, { call, put }: any) {
  try {
    const { pulseIds, callback } = payload;
    const response: any = yield call(serviceExportPulses, pulseIds);
    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
    });
    const link = document.createElement('a');
    link.setAttribute('download', 'PulsesSummary.xlsx');
    link.setAttribute('href', window.URL.createObjectURL(blob));
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    callback?.();
  } catch (error) {
    notification.error({ message: 'Something went wrong' });
  }
  return true;
}

export default exportPulses;
