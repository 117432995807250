import { notification } from 'antd';
import { servicePulseDetails } from '@/services/singlePulse';

function* getPulse({ payload }: any, { call, put }: any) {
  try {
    const { id, callback } = payload;
    const { meta, data } = yield call(servicePulseDetails, id);

    if (meta?.ok) {
      yield put({
        type: 'savePulse',
        payload: { pulse: data },
      });

      if (typeof callback === 'function') {
        callback();
      }

      return;
    }

    notification.error({ message: 'Failed to get pulse', description: meta?.message });
  } catch (error) {
    notification.error({ message: 'Something went wrong' });
  }
  return true;
}

export default getPulse;
