import { notification } from 'antd';
import { serviceGetPulseCollectors } from '@/services/singlePulse';

function* getPulseCollectors({ payload }: any, { call, put }: any) {
  try {
    const { pulseId, search, callback } = payload;
    const { meta, data } = yield call(serviceGetPulseCollectors, pulseId, search,);
    if (meta?.ok) {
      yield put({
        type: 'savePulseCollectors',
        payload: { collectors: data?.pulseCollectors },
      });
      if (typeof callback === 'function') {
        callback();
      }
      return;
    }

    notification.error({ message: 'Failed to get pulse collectors', description: meta?.message });
  } catch (error) {
    notification.error({ message: 'Something went wrong' });
  }
  return true;
}

export default getPulseCollectors;
