import { routerRedux } from 'dva/router';
import { stringify, parse } from 'qs';
import { unsubscribe } from '@/utils/oneSignal';
import { removeTokenAuthority } from '@/utils/authority';

export function getPageQuery() {
  return parse(window.location.href.split('?')[1]);
}
const Model = {
  namespace: 'login',
  state: {
    status: undefined,
  },
  effects: {
    *logout(_, { put, call }) {
      const { redirect } = getPageQuery(); // redirect
      yield call(removeTokenAuthority);
      if (window.location.pathname !== '/user/login' && !redirect) {
        yield put(
          routerRedux.replace({
            pathname: '/login',
            search: stringify({
              redirect: window.location.href,
            }),
          })
        );
        unsubscribe();
        yield put({ type: 'user/updateSubscribe', payload: false });
      }
    },
  },
  reducers: {
    changeLoginStatus(state, { payload }) {
      return { ...state, status: payload.status, type: payload.type };
    },
  },
};
export default Model;
