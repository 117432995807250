import { request } from '@/utils/request';

export function deleteItem(payload) {
  return request(`/prototype/questions/${payload}`, {
    method: 'DELETE',
  });
}

export function getDataQuestionList(payload) {
  const myFilter = !payload.filter ? [] : payload.filter.toString().replace(/,/g, '-');
  const mySorter = !payload?.sorter ? [] : payload?.sorter;
  const mySearchValue = !payload?.value ? [] : payload?.value;
  const filterString = myFilter.length !== 0 ? 'status' : [];
  const sortByString = mySorter.length !== 0 ? 'updatedAt' : [];
  
  return request(`/prototype/questions`, {
    params: {
      limit: payload.pageSize,
      offset: payload.current - 1,
      filter: filterString,
      value: myFilter,
      title: mySearchValue,
      sortBy: sortByString,
      order: mySorter,
    },
  });
}

export function createNewQuestion({ data }) {
  return request(`/prototype/questions`, {
    method: 'POST',
    data,
  });
}

export function editQuestion({ question, id }) {
  return request(`/prototype/questions/${id}`, {
    method: 'PUT',
    data: question,
  });
}
