import { notification } from 'antd';
import { history } from 'umi';
import { fetchCollectionDetails } from '@/services/collection';

function* details(action, { call, put }) {
  const res = yield call(fetchCollectionDetails, action.payload);
  if (res.isSuccess) {
    const { data, meta } = res;
    yield put({
      type: 'appendDetails',
      payload: { meta, data },
    });

    yield put({
      type: 'sponsor/append',
      payload: {
        meta: res.meta,
        data: res.data.sponsors,
      },
    });
    yield put({
      type: 'collector/append',
      payload: {
        meta: res.meta,
        data: res.data.collectors,
      },
    });
  } else {
    notification.error({
      message: 'Error',
      description: res.getErrorMessage() || 'Can not found Collection list',
    });
    history.push('/admin/collection'); // Redirect to homepage if code is invalid
  }
}

export default details;
