export default class Path {
  static SINGLE_PULSE_PATH = '/admin/pulses';

  static SINGLE_PULSE_CREATE = '/admin/pulse';

  static SINGLE_PULSE_CREATE_QUESTION = '/admin/pulses/:id/question';

  static SINGLE_PULSE_DETAILS = '/admin/pulses/:id';

  static PULSE_SUMMARY = '/admin/pulses/:id/statistic';

  static ACTIVITIES_HISTORY = `/admin/pulses/:slug/history`;
}
