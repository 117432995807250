import { notification } from 'antd';
import { history } from 'umi';
import messageSuccess from '@/components/CustomizedMessage/success';
import { create as createCollection } from '@/services/collection';

function* create({ payload }, { call }) {
  const { data: body } = payload;
  try {
    const { meta, data } = yield call(createCollection, body);

    if (!meta.ok) return notification.error({ message: 'Failed to create Collection' });
    const { id } = data;
    messageSuccess('You have successfully update collection title');
    return history.push(`/admin/collection/${id}`);
  } catch (error) {
    return notification.error({ message: 'Failed to create Collection' });
  }
}

export default create;
