import { parse } from 'qs';

const urlToList = url => {
  const list = url.split('/').filter(i => i);
  return list.map((urlItem, index) => {
    return `/${list.slice(0, index + 1).join('/')}`;
  });
};

const getQueryUrl = () => {
  const params = parse(window.location.search.replace(/^\?/, ''));
  if (Object.keys(params).length > 0) {
    return params;
  }
  return null;
};

export { urlToList, getQueryUrl };
