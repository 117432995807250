import { cloneDeep } from "lodash";

export default function updateOthersAnswerOfQuestionInList(state: QuestionModel.QuestionModelState, { payload }: { payload: any}) {
   // Suspicious
    const { list } = state;
    const newList = cloneDeep(list);
    const index = newList.findIndex(el => el.questionId === payload.questionId);
    if (payload.add && !newList[index]?.pulselyOption?.includes('Others')) {
      newList?.[index].pulselyOption?.push({
        isOptionText: true,
        optionId: 0,
        optionTitle: 'Others',
        optionType: 1,
      });
    } else {
      newList?.[index].pulselyOption?.pop();
    }

    return { ...state, list: newList };
  };