import { notification } from 'antd';
import { delay } from 'dva/saga';
import { getQueryUrl } from '@/utils/pathTools';
import messageSuccess from '@/components/CustomizedMessage/success';
import configSite from '../../config/configSite';
import {
  create,
  duplicateSponsor,
  query,
  addSponsor,
  searchSponsor,
  removeSponsor,
} from '@/services/sponsor';

export default {
  namespace: 'sponsor',

  state: {
    sponsors: [],
    data: {},
    tempSponsor: {},
  },

  effects: {
    *fetch({ payload }, { call, put }) {
      const response = yield call(query, payload);
      if (response.isSuccess) {
        yield put({
          type: 'append',
          payload: {
            meta: response.meta,
            data: response.data,
          },
        });
      }
    },
    *create({ payload }, { call }) {
      const body = { ...payload, sponsors: [] };
      const res = yield call(create, body);
      if (!res.isSuccess) {
        notification.error({ message: res.getErrorMessage('Failed to create sponsor list') });
      }
    },
    *addSponsor({ payload }, { call, put }) {
      const { id, sponsors, formRef } = payload;
      const body = { sponsors };
      const res = yield call(addSponsor, id, body);
      if (formRef) {
        formRef.current.resetFields();
      }

      if (!res.isSuccess) {
        notification.error({ message: res.getErrorMessage('Failed to create sponsor list') });
      } else {
        yield put({
          type: 'details',
          payload: id,
        });
      }
    },
    // To do
    // *update({ payload }, { call, put }) {
    //   const { slug, body } = payload;
    //   const res = yield call(updateSponsor, slug, body);
    //   if (res.isSuccess) {
    //     const response = yield call(query);
    //     yield put({
    //       type: 'append',
    //       payload: {
    //         meta: response.meta,
    //         data: response.data,
    //       },
    //     });
    //   }
    // },
    *duplicate(action, { call, put }) {
      const res = yield call(duplicateSponsor, action.payload);
      if (res.isSuccess) {
        yield put({
          type: 'fetch',
          payload: getQueryUrl(),
        });
        yield put({
          type: 'appendDuplicateStatus',
          payload: true,
        });
      }
    },
    *searchSponsor({ payload }, { call, put }) {
      const response = yield call(searchSponsor, payload);
      if (response.isSuccess) {
        yield put({
          type: 'append',
          payload: {
            data: response.data,
          },
        });
      }
    },
    *removeSponsor({ payload }, { call, put, race, take, select }) {
      const { collectionId, sponsorId } = payload;
      const { sponsors } = yield select(model => model.sponsor);
      const deletedSponsors = sponsors.find(item => item.id === sponsorId);
      yield put({
        type: 'saveTempSponsor',
        payload: deletedSponsors,
      });
      const { undo, task } = yield race({
        undo: take(action => action.type === `sponsor/undoRemove/${sponsorId}`),
        task: delay(configSite.TIME_FOR_UNDO),
      });
      if (undo) {
        // revert thread to previous state
        const { tempSponsor } = yield select(model => model.sponsor);
        yield put({
          type: 'appendSponsor',
          payload: tempSponsor,
        });
        messageSuccess(`You have successfully undo with removing sponsor`);
        return;
      }
      if (task) {
        // make the API call to apply the changes remotely
        const res = yield call(removeSponsor, collectionId, sponsorId);
        if (res.isSuccess) {
          yield put({
            type: 'collection/details',
            payload: collectionId,
          });
          notification.success({
            message: 'Remove sponsors success',
          });
        } else {
          notification.error({
            message: res?.meta?.message || `Can not remove sponsor`,
          });
        }
      }
    },
  },
  reducers: {
    append(state, action) {
      const {
        payload: { data },
      } = action;
      return {
        ...state,
        sponsors: data || [],
      };
    },
    appendDetails(state, action) {
      const {
        payload: { data, meta },
      } = action;
      return {
        ...state,
        data: data || [],
        status: meta.ok,
      };
    },
    appendDuplicateStatus(state, action) {
      return {
        ...state,
        statusDuplicate: action.payload,
      };
    },
    saveAnswers(state, action) {
      return {
        ...state,
        answers: action.payload.answers,
      };
    },

    appendFeeling(state, action) {
      return {
        ...state,
        feeling: action.payload,
      };
    },

    appendSlug(state, action) {
      return {
        ...state,
        dataSlug: action.payload,
      };
    },

    appendWelcome(state, action) {
      return {
        ...state,
        welcome: action.payload,
      };
    },
    appendSubmit(state, action) {
      return {
        ...state,
        trash: true,
        submit: action,
      };
    },

    appendPreviewData(state, action) {
      return {
        ...state,
        previewData: action.payload,
      };
    },
    appendExport(state, action) {
      return {
        ...state,
        dataExport: action.payload,
      };
    },

    appendGeneralInfo(state, action) {
      const { generalInfo } = state;
      return {
        ...state,
        generalInfo: { ...generalInfo, ...action.payload },
      };
    },

    appendSponsorLink(state, action) {
      return {
        ...state,
        sponsorLink: action.payload,
      };
    },

    appendQuestion(state, action) {
      return {
        ...state,
        data: action.payload,
      };
    },

    appendModifyUser(state, action) {
      return {
        ...state,
        dataModify: action.payload,
      };
    },
    appendSpecificList(state, action) {
      return {
        ...state,
        listSponsorSpecific: action.payload,
      };
    },
    appendPrivacy(state, action) {
      return {
        ...state,
        privacyChecked: action.payload,
      };
    },
    appendEmail(state, action) {
      return {
        ...state,
        listEmail: action.payload,
      };
    },
    setUnsaved(state, { payload }) {
      return {
        ...state,
        unsaved: payload.unsaved,
      };
    },
    toggleRemindCheckInModal(state, action) {
      return {
        ...state,
        remindCheckIn: action.payload,
      };
    },
    updateSponsor(state, { payload }) {
      const newSponsors = [...state.sponsors];
      payload.forEach(item => {
        const updatedSponsorIndex = state.sponsors.findIndex(sponsor => sponsor.id === item.id);
        newSponsors.splice(updatedSponsorIndex, 1, item);
      });
      return {
        ...state,
        sponsors: newSponsors,
      };
    },

    saveTempSponsor(state, { payload }) {
      return {
        ...state,
        tempSponsor: payload,
      };
    },
    appendSponsor(state, { payload }) {
      return {
        ...state,
        sponsors: [...state.sponsors, payload],
      };
    },
  },
};
