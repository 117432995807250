// File service summary
import { request } from '@/utils/request';

export function exportPDFStatus(surveyId, jobId) {
  return request(`/pulses/${surveyId}/summary/pdf/status?jobId=${jobId}`);
}

export function exportPDFDownload(surveyId) {
  return request(`/pulses/${surveyId}/summary/pdf/link`);
}

async function handleGetStatusExport(surveyId, jobId) {
  const excutePDFStatus = async () => {
    const data = await exportPDFStatus(surveyId, jobId);
    if (data.data.status === 'IN_PROGRESS') {
      return excutePDFStatus();
    }
    return data;
  };

  const response = await excutePDFStatus();

  return response;
}

export async function exportPDF(surveyId) {
  const runExport = await request(`/pulses/${surveyId}/summary/pdf/run`, {
    method: 'POST',
  });

  if (!runExport.meta.ok) return runExport;
  const getStatus = await handleGetStatusExport(surveyId, runExport.data.jobId);

  if (getStatus?.data?.status !== 'COMPLETED') return getStatus;
  const getLinkPdf = await exportPDFDownload(surveyId);

  if (getLinkPdf.meta.ok) {
    const element = document.createElement('a');
    element.setAttribute('href', getLinkPdf.data.url);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  return getLinkPdf;
}

export function getSummary(surveyId) {
  return request(`/pulses/${surveyId}/summary`);
}

export function getSponsorsInQuestion(payload) {
  return request(`/pulses/${payload.pulseId}/questions/${payload.questionId}/sponsors`, {
    method: 'GET',
    params: {
      query: payload.searchTerm,
    },
  });
}

export const getGeneralInfo = payload => {
  return request(`/collectors/pulses/${payload}`);
};

export const getSponsorsJoinedInfo = payload => {
  return request(`/pulses/${payload}/sponsors`);
};

export const getSummaryFile = payload => {
  return request(`/collectors/pulses/${payload}/export-summary`, {
    responseType: 'blob',
  });
};
