const ConfigSite = {
  STATUS: {
    DRAFT: 0,
    WAITING: 1,
    RUNNING: 2,
    CLOSED: 3,
  },
  STATUS_NAME: {
    0: 'draft',
    1: 'waiting',
    2: 'running',
    3: 'closed',
  },
  CALL_TO_ACTION: {
    NEED_TO_CONFIRM_INVITE: 'NEEDTOCONFIRM',
    REJECTED: 'REJECTED',
    WAIT_FOR_CONFIRM: 'WAITFORCONFIRM',
    CANCELLED_BY_SPONSOR: 'CANCELLEDBYSPONSOR',
    REJECTED_BY_COLLECTOR: 'REJECTEDBYCOLLECTOR',
    WAIT_FOR_DO: 'WAITFORDO',
    DO_PULSE: 'DOPULSE',
    DONE_PULSE: 'DONEPULSE',
    PULSE_EXPIRED: 'PULSEEXPIRED',
    NEED_TO_ACCEPT: 'NEEDTOACCEPT',
  },
  SPONSOR_STAGE: {
    INVITED: 'INVITED',
    CONFIRMED: 'CONFIRMED',
    REJECTED: 'REJECTED',
    REQUESTED: 'REQUESTED',
    REQUESTEDANDCHECKIN: 'REQUESTEDANDCHECKIN',
    ACCEPTED: 'ACCEPTED',
    CANCELLED: 'CANCELLED',
    REJECTEDBYCOLLECTOR: 'REJECTEDBYCOLLECTOR',
    NONE: 'NONE',
    REGISTERED: 'DONE R',
    UNREGISTERED: 'UNDONE R',
    CHECKIN: 'DONE C',
    NOTCHECKIN: 'UNDONE C',
    DONE: 'DONE',
    UNDONE: 'UNDONE',
  },
  USER_TYPE: {
    USER: 0,
    ADMIN: 1,
    GUEST: 2,
    EXTERNAL: 3,
  },
  QUESTION_TYPE: {
    TEXT: 0,
    SINGLE: 1,
    MULTIPLE: 2,
    FEELING: 3,
    RATING: 4,
    RANKING: 5,
    SLIDER: 6,
    SORTING: 7,
    ASSESS: 8,
    UPLOAD: 9,
    KEYWORD: 10,
    BRANCHING: 11,
  },
  ANSWER_TYPE: {
    TEXT: 0,
    MEDIA: 1,
  },
  OPTION_TYPE: {
    OPTION_TEXT: 0,
    OPTION_MEDIA: 1,
  },
  FEELING_VALUE: [0, 25, 50, 75, 100],
  TEMPLATE_TYPE: {
    DEFAULT: 0,
    NORMAL: 1,
  },
  SURVEY_TYPE: {
    BEFORE: 0,
    AFTER: 1,
    NORMAL: 2,
    PRIVACY: 3,
  },
  PULSE_TYPE: {
    NORMAL: 0,
    EVENT: 1,
    ASSESS: 2,
    MULTIPLE_SUBMISSIONS: 3,
    REGISTER: 4,
    CHECK_IN: 5,
  },
  QUESTION_FORM: {
    Feeling_Position: -2,
    New_Question_Position: -99,
    Non_Edit_Position: -1,
  },
  CONSENSUS_STATUS: {
    UNSET: 0,
    SET: 1,
  },
  ALLOW_MULTIPLE_ANSWER: {
    UNSET: 0,
    SET: 1,
  },
  SOCKET_EVENT: {
    LIVE_SWITCH: 'live:switch-mode',
    LIVE_JOIN: 'live:join',
    LIVE_QUESTION: 'live:switch-question',
    QUESTION_CHANGE: 'live:question-change',
    GET_QUESTION_RESULT: 'live:new-submission',
    SUBMIT_ANSWER: 'live:submit-answer',
    SUMMARY_CHANGE: 'live:summary-change',
    TOTAL_SPONSOR_CHANGE: 'live:total-sponsor-change',
    SUBMITTED_SPONSOR_CHANGE: 'live:submitted-sponsor-change',
    SPONSOR_REACTION: 'live:react-question',
    NEW_REACTION: 'live:new-reaction',
    PULSE_STATUS_CHANGE: 'live:pulse-status-change',
    LIVE_OFF: 'live:turn-off',
    NEW_SPONSOR_JOIN: 'live:new-sponsor-join',
    SPONSOR_LEAVE: 'live:sponsor-leave',
  },
  SOCKET_ROLE: {
    SPONSOR: 'sponsor',
    COLLECTOR: 'collector',
  },
  SUMMARY_TAB: {
    GENERAL_INFO: '0',
    SUMMARY: '1',
    LIVE_INSIGHT: '2',
  },
  TIME_FOR_UNDO: 3000, // ms
  LIMIT: 10,
  SHORTLY_URL: '',
};

export default ConfigSite;
