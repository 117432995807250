import { notification } from 'antd';
import { addQuestions, queryDetail } from '@/services/question';
import ConfigSite from '@/../config/configSite';
import { Question } from '@/types/question';

const { QUESTION_TYPE } = ConfigSite;

interface CreateQuestionsPayload {
  questions: Question[];
  pulseId: number | string;
  callback?: () => void;
}

function* createQuestions({ payload }: { payload: CreateQuestionsPayload }, { call, put }) {
  const { questions, pulseId, callback } = payload;

  try {
    const response = yield call(addQuestions, pulseId, questions);

    if (!response?.meta?.ok) {
      return notification.error({ message: response?.meta?.message || 'Something went wrong' });
    }
    if (typeof callback === 'function') callback();

    const hasBranching =
      questions?.findIndex(q => q?.questionType === QUESTION_TYPE.BRANCHING) > -1;

    if (hasBranching) {
      yield put({
        type: 'getAvailableBranches',
        payload: pulseId,
      });
    }

    yield put({
      type: 'fetch',
      payload: {
        params: pulseId,
      },
    });

    notification.success({ message: 'Create question successfully' });
    return true;
  } catch (error) {
    console.error(error);
  }
}

export default createQuestions;
