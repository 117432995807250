import { notification } from 'antd';
import { serviceChangePulse } from '@/services/singlePulse';

interface Payload {
  pulseId: string;
  status: number;
  callback?: () => void;
}

function* changePulseStatus({ payload }: { payload: Payload }, { call, put }: any) {
  try {
    const { pulseId, status, callback } = payload;

    const { meta, data } = yield call(serviceChangePulse, pulseId, status);
    if (meta?.ok) {
      yield put({
        type: 'savePulse',
        payload: { pulse: data },
      });
      notification.success({ message: 'Change status successfully' });

      yield put({ type: 'updateStatusPulse', payload: { pulseId, status } });

      if (typeof callback === 'function') {
        callback();
      }
      return;
    }
    notification.error({ message: 'Failed to change status', description: meta?.message });
  } catch (error) {
    notification.error({ message: 'Something went wrong' });
  }
  return true;
}

export default changePulseStatus;
