import { isNumber } from "lodash";
import { produce } from "immer";

import { Question } from "@/types/question";

export default function updateQuestionFeeling(state: QuestionModel.QuestionModelState, { payload }: { payload: any}) {
    const body = payload?.body;
    const questionId = payload?.questionId;
    const order = payload?.order;
    const nextState = produce(state.list, draft => {
      if (!isNumber(order)) {
        draft.push(body[body.length - 1]);
        return;
      }

      if (questionId) {
        const question = body.find((item: Question) => item?.questionId === questionId);
        draft[order - 1] = { ...question, order };
      } else {
        draft[order - 1] = { ...body[body?.length - 1], order };
      }
    });
    return {
      ...state,
      questions: nextState,
      list: nextState,
      generalInfo: payload?.generalInfo,
    };
  }