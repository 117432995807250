import { queryDetail } from '@/services/question';
import { fetchGeneralInfoAdmin } from '@/services/survey';
import ConfigSite from '@/../config/configSite';

const { QUESTION_TYPE } = ConfigSite;

function* getQuestion({ payload }, { call, put }) {
  const { pulseId, callback } = payload;

  const response = yield call(queryDetail, pulseId);

  if (response.isSuccess) {
    const { data } = response;

    yield put({
      type: 'survey/appendGeneralInfo',
      payload: { ...data?.generalInfo },
    });

    yield put({
      type: 'append',
      payload: data,
    });

    const branchingQuestion = data?.body?.find(
      question => question.questionType === QUESTION_TYPE.BRANCHING
    );

    try {
      const questionsBeforeBranching = data.body?.filter(
        question => question?.order < branchingQuestion?.order
      );
      if (questionsBeforeBranching) {
        yield put({
          type: 'setQuestionBeforeBranching',
          payload: questionsBeforeBranching,
        });
      }
    } catch (e) {
      console.error('No branching question', e);
    }

    if (typeof callback === 'function') {
      callback();
    }
  }
  return;
}

export default getQuestion;
