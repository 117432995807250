import { notification } from 'antd';
import { history } from 'umi';
import messageSuccess from '@/components/CustomizedMessage/success';
import { duplicate as duplicateCollection } from '@/services/collection';

function* duplicate({ payload }, { call }) {
  const { data, formRef, pulseCollectionId } = payload;
  const body = { ...data, title: `[Duplicate] ${data?.title}` };

  const res = yield call(duplicateCollection, body, pulseCollectionId);
  if (formRef) {
    formRef.current.resetFields();
  }

  if (!res.isSuccess) {
    notification.error({ message: res.getErrorMessage('Failed to duplicate Collection') });
  } else {
    const { id } = res.data;
    messageSuccess(`You have successfully duplicate`);
    history.push(`/admin/collection/${id}`);
  }
}

export default duplicate;
