import { request } from '@/utils/request';

export function getLiveStatus(pulseId) {
  return request(`/lives/pulses/${pulseId}/status`);
}

export function getLiveSummary({ pulseId, questionId }) {
  return request(
    `/lives/pulses/${pulseId}/questions/${questionId}/summary`
  );
}

export function getSubmissionStatus({ slug }) {
  return request(`/pulses/${slug}/submissions/status`);
}

export async function sponsorGetReactionState(slug, questionId) {
  return request.get(`/lives/pulses/${slug}/questions/${questionId}/reaction`);
}

export function getSponsorsJoinedLive({ pulseId }) {
  return request(`/lives/pulses/${pulseId}/joined-sponsors`);
}
