import { delay } from 'dva/saga';
import { notification } from 'antd';

import { serviceDeletePulseSponsors } from '@/services/singlePulse';
import ConfigSite from '@/../config/configSite';
import messageSuccess from '@/components/CustomizedMessage/success';

function* deletePulseSponsors({ payload }: any, { call, take, race, put, select }: any) {
  try {
    const { pulseId, sponsorIds, callback } = payload;

    // Remove sponsors from store
    const oldSponsors: any[] = yield select((model: any) => model.pulse.sponsors);
    const newSponsors: any[] = oldSponsors.filter(
      sponsor => !sponsorIds.includes(sponsor.sponsorId)
    );

    yield put({
      type: 'savePulseSponsors',
      payload: {
        sponsors: newSponsors,
      },
    });

    const { undo, task } = yield race({
      undo: take((action: any) => action.type === `pulse/undoDeletePulseSponsors`),
      task: delay(ConfigSite.TIME_FOR_UNDO),
    });

    if (undo) {
      // revert thread to previous state
      yield put({
        type: 'savePulseSponsors',
        payload: {
          sponsors: oldSponsors,
        },
      });
      messageSuccess(`You have successfully undo with deleting sponsors`);
      return;
    }

    if (task) {
      const { meta } = yield call(serviceDeletePulseSponsors, pulseId, sponsorIds);

      if (meta?.ok) {
        if (typeof callback === 'function') {
          callback();
        }

        return notification.success({ message: 'Delete sponsors successfully' });
      }

      // revert thread to previous state
      yield put({
        type: 'savePulseSponsors',
        payload: {
          sponsors: oldSponsors,
        },
      });
      notification.error({
        message: 'Failed to delete pulse sponsors',
        description: meta?.message,
      });
    }
  } catch (error) {
    notification.error({ message: 'Something went wrong' });
  }
  return true;
}

export default deletePulseSponsors;
