import { notification } from 'antd';
import { updateQuestion, queryDetail } from '@/services/question';
import ConfigSite from '@/../config/configSite';

const { QUESTION_TYPE } = ConfigSite;

function* update({ payload }, { call, put }) {
  const { pulseId, question, options = [], callback } = payload;
  try {
    const response = yield call(updateQuestion, pulseId, question.questionId, question, options);
    if (!response.isSuccess) return notification.error({ message: 'Something went wrong' });

    const responseDetail = yield call(queryDetail, pulseId);
    if (!responseDetail.isSuccess) return notification.error({ message: 'Something went wrong' });

    const { data } = responseDetail;
    yield put({
      type: 'survey/appendGeneralInfo',
      payload: { updatedAt: data.generalInfo.updatedAt },
    });

    yield put({
      type: 'updateQuestion',
      payload: { ...data, questionId: question.questionId, order: question?.order },
    });

    if (question.questionType === QUESTION_TYPE.BRANCHING) {
      yield put({
        type: 'getAvailableBranches',
        payload: pulseId,
      });
    }

    notification.success({ message: 'Update question successfully' });

    return true;
  } catch (error) {
    notification.error({ message: 'Something went wrong' });
  } finally {
    if (callback) callback();
  }
}

export default update;
