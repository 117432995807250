import { notification } from 'antd';
import { deleteArchiveCollectionService } from '@/services/collection';

interface IGeneratorArchived {
  payload: {
    title: string;
    collectionId: string;
    params: any;
    status: string;
    callback?: () => void;
    callbackLoadingArchive?: () => void;
  };
}

function* generatorArchivedRestoreCollection({ payload }: IGeneratorArchived, { call, put }: any) {
  const { collectionId, callback, params, status, title, callbackLoadingArchive } = payload;
  try {
    const { meta } = yield call(deleteArchiveCollectionService, collectionId, title, status);
    if (!meta.ok) return notification.error({ message: `Failure to ${status} collection` });
    notification.success({
      message: `${status === 'archived' ? 'Archived' : 'Active'} collection successfully`,
    });
    yield put({ type: 'fetch', payload: { ...params } });
    if (callback) {
      callback();
    }
  } catch (error) {
    notification.error({ message: `Failure to ${status} collection` });
  } finally {
    if (callbackLoadingArchive) {
      callbackLoadingArchive();
    }
  }
  return true;
}

export default generatorArchivedRestoreCollection;
