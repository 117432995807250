import { getAvailableBranches as getAvailableBranchesService } from '@/services/question';

function* getAvailableBranches({ payload }, { call, put }) {
  try {
    const getBranches = yield call(getAvailableBranchesService, payload);
    if (getBranches.isSuccess) {
      yield put({
        type: 'saveAvailableBranches',
        payload: getBranches.data,
      });
    } else {
      yield put({
        type: 'saveAvailableBranches',
        payload: [],
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default getAvailableBranches;
