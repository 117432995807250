import { getModifyUser, modifyMember, getGuestMail, modifyGuestMail } from '@/services/survey';
import { notification } from 'antd';
import ConfigSite from '../../config/configSite';

export default {
  namespace: 'member',
  state: {
    members: [],
    numberMembers: 0,
    survey: null,
    dataGuestMails: [],
  },
  effects: {
    *getModifyUser(action, { call, put }) {
      const res = yield call(getModifyUser, action.payload);
      if (res.isSuccess) {
        yield put({
          type: 'appendModifyUser',
          payload: res.data,
        });
      } else {
        notification.error({ message: res.getErrorMessage('Failed to get members') });
      }
    },

    *modifyMember(action, { call, put }) {
      const res = yield call(modifyMember, action.payload);
      if (res.isSuccess) {
        yield put({
          type: 'survey/fetch',
        });
      } else {
        notification.error({ message: res.getErrorMessage('Failed to update members') });
      }
    },

    *getGuestMail(action, { call, put }) {
      const res = yield call(getGuestMail, action.payload);
      if (res.isSuccess) {
        yield put({
          type: 'appendGuestMail',
          payload: res.data.listEmail,
        });
      } else {
        notification.error({ message: res.getErrorMessage('Failed to update guest mail') });
      }
    },
    *modifyGuestMail(action, { call }) {
      yield call(modifyGuestMail, action.payload);
    },
  },

  reducers: {
    appendModifyUser(state, action) {
      const { userData = [], generalInfo: { surveyId } = { surveyId: null } } = action.payload;
      return {
        ...state,
        members: userData.sort((a, b) => {
          return b.status - a.status ? b.status - a.status : a.username.localeCompare(b.username);
        }),
        numberMembers: userData.length,
        surveyId,
      };
    },

    appendMembers(state, { payload }) {
      const { members = [] } = payload;
      return {
        ...state,
        members,
        numberMembers: members.length,
      };
    },

    appendGuestMail(state, action) {
      return {
        ...state,
        dataGuestMails: action.payload,
      };
    },

    checkInAll(state) {
      const { members = [] } = state;
      return {
        ...state,
        members: members.map(member => ({
          ...member,
          status: member.userType === ConfigSite.USER_TYPE.USER ? true : member.status,
        })),
      };
    },

    unCheckInAll(state) {
      const { members = [] } = state;
      return {
        ...state,
        members: members.map(member => ({
          ...member,
          status: false,
        })),
      };
    },
  },
};
