import { GetResourceParameters, ResourceReqBody } from '@/types/resource';
import request from '@/utils/request';

const RESOURCES_ENDPOINT = '/resources';

export async function getResources(params: GetResourceParameters): Promise<any> {
  return request(RESOURCES_ENDPOINT, {
    method: 'GET',
    params: {
      ...params,
    },
  });
}

export async function getResourceById(id: string | number): Promise<any> {
  if (!id) return null;
  return request(`${RESOURCES_ENDPOINT}/${id}`, {
    method: 'GET',
  });
}

export async function createResource(data: ResourceReqBody): Promise<any> {
  return request(RESOURCES_ENDPOINT, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export async function updateResourceById(
  payload: ResourceReqBody,
  id: string | number
): Promise<any> {
  if (!id) return null;
  return request(`${RESOURCES_ENDPOINT}/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(payload),
  });
}

export async function deleteResourceById(id: string | number): Promise<any> {
  if (!id) return null;
  return request(`${RESOURCES_ENDPOINT}/${id}`, {
    method: 'DELETE',
  });
}
