/* eslint-disable compat/compat */
import { Manager } from 'socket.io-client';
import { getToken } from '@/utils/authority';
import ConfigSite from '../../config/configSite';

const { SOCKET_EVENT, SOCKET_ROLE } = ConfigSite;

const manager = new Manager(`${API_URL}`, {
  autoConnect: false,
  path: '/live',
  reconnectionDelay: 1000,
  timeout: 20000,
  forceNew: true,
  extraHeaders: {
    Authorization: `Bearer ${getToken()}`,
  },
});

const socket = manager.socket('/');

export function joinRoom({ pulseId, role }) {
  return socket.emit(SOCKET_EVENT.LIVE_JOIN, { pulseId, role }, () => {});
}

const eventsCollectorConnect = ({ pulseId, dispatch }) => {
  joinRoom({ pulseId, role: SOCKET_ROLE.COLLECTOR });
  dispatch({ type: 'liveInsight/setConnectedSocket', payload: false });
  dispatch({
    type: 'liveInsight/getLiveStatus',
    payload: {
      pulseId,
    },
  });

  dispatch({
    type: 'liveInsight/getLiveStatusCollector',
    payload: {
      pulseId,
    },
  });

  dispatch({
    type: 'liveInsight/getSponsorsJoinedLive',
    payload: {
      pulseId,
    },
  });
};

const eventsSponsorConnected = ({ pulseId, slug, dispatch }) => {
  joinRoom({ pulseId, role: SOCKET_ROLE.SPONSOR });
  dispatch({ type: 'liveInsight/setConnectedSocket', payload: false });
  dispatch({
    type: 'liveInsight/getQuestion',
    payload: pulseId,
  });

  dispatch({
    type: 'liveInsight/getLiveStatus',
    payload: {
      pulseId,
    },
  });

  dispatch({
    type: 'liveInsight/getSubmissionStatus',
    payload: {
      slug,
    },
  });
};

export function collectorConnectSocket(pulseId, dispatch) {
  socket.io.opts.query = {
    socketId: socket?.id || '',
  };
  socket.connect();
  socket.on('connect', () => {
    if (socket.connected) {
      eventsCollectorConnect({ pulseId, dispatch });
    }
  });

  socket.on('connect_error', () => {
    if (!socket.connected) {
      dispatch({ type: 'liveInsight/setConnectedSocket', payload: true });
    }
  });

  socket.on('disconnect', () => {
    dispatch({ type: 'liveInsight/setConnectedSocket', payload: false });
  });
}

export function sponsorConnectLive({ pulseId, dispatch, slug }) {
  socket.io.opts.query = {
    socketId: socket?.id || '',
  };
  socket.connect();
  socket.on('connect', () => {
    eventsSponsorConnected({ pulseId, slug, dispatch });
  });

  socket.on('connect_error', () => {
    dispatch({ type: 'liveInsight/setConnectedSocket', payload: true });
  });

  socket.on('disconnect', () => {
    dispatch({ type: 'liveInsight/setConnectedSocket', payload: false });
  });
}

export function disconnectSocket() {
  socket.io.opts.query = {
    socketId: socket?.id || '',
  };
  socket.removeAllListeners();
  return socket.disconnect();
}

export function liveOn({ pulseId }) {
  return socket.emit(
    SOCKET_EVENT.LIVE_SWITCH,
    {
      pulseId,
      mode: 1,
    },
    () => {}
  );
}

export function liveOff({ pulseId }) {
  return socket.emit(
    SOCKET_EVENT.LIVE_SWITCH,
    {
      pulseId,
      mode: 0,
    },
    () => {}
  );
}

export function liveQuestion({ collectionId, pulseId, questionId }) {
  return socket.emit(
    SOCKET_EVENT.LIVE_QUESTION,
    {
      collectionId,
      pulseId,
      nextQuestionId: questionId,
    },
    () => {}
  );
}

export const submitAnswer = ({ submissionId, pulseId, questionId, answers }) =>
  new Promise(res => {
    socket.emit(
      SOCKET_EVENT.SUBMIT_ANSWER,
      {
        pulseId,
        questionId,
        submissionId,
        answers,
      },
      response => res(response)
    );
  });

export function listenQuestionChange(callback) {
  socket.on(SOCKET_EVENT.QUESTION_CHANGE, res => {
    callback(res);
  });
}

export function muteChangeQuestion() {
  socket.off(SOCKET_EVENT.QUESTION_CHANGE);
}

export function listenSummaryChange(callback) {
  socket.on(SOCKET_EVENT.SUMMARY_CHANGE, res => {
    callback(res);
  });
}

export function muteSummaryChange() {
  socket.off(SOCKET_EVENT.SUMMARY_CHANGE);
}

export function listenTotalSponsorChange(callback) {
  socket.on(SOCKET_EVENT.TOTAL_SPONSOR_CHANGE, res => {
    callback(res);
  });
}

export function muteTotalSponsorChange() {
  socket.off(SOCKET_EVENT.TOTAL_SPONSOR_CHANGE);
}

export function listenSubmittedSponsorChange(callback) {
  socket.on(SOCKET_EVENT.SUBMITTED_SPONSOR_CHANGE, res => {
    callback(res);
  });
}

export function muteSubmittedSponsorChange() {
  socket.off(SOCKET_EVENT.SUBMITTED_SPONSOR_CHANGE);
}

export function sponsorReaction(pulseId, questionId, reactionType) {
  return socket.emit(
    SOCKET_EVENT.SPONSOR_REACTION,
    {
      pulseId,
      questionId,
      reactionType,
    },
    res => {
      console.error(res);
    }
  );
}

export function listenReactionService(callback) {
  socket.on(SOCKET_EVENT.NEW_REACTION, res => {
    callback(res);
  });
}

export function muteListenReactionService() {
  socket.off(SOCKET_EVENT.NEW_REACTION);
}

export function listenPulseStatusChange(callback) {
  socket.on(SOCKET_EVENT.PULSE_STATUS_CHANGE, res => {
    callback(res);
  });
}

export function mutePulseStatusChange() {
  socket.off(SOCKET_EVENT.PULSE_STATUS_CHANGE);
}

export function listenLiveOff(callback) {
  socket.on(SOCKET_EVENT.LIVE_OFF, res => {
    callback(res);
  });
}

export function muteLiveOff() {
  socket.off(SOCKET_EVENT.LIVE_OFF);
}

export function listenNewSponsorJoin(callback) {
  socket.on(SOCKET_EVENT.NEW_SPONSOR_JOIN, res => {
    callback(res);
  });
}

export function muteNewSponsorJoin() {
  socket.off(SOCKET_EVENT.NEW_SPONSOR_JOIN);
}

export function listenSponsorLeave(callback) {
  socket.on(SOCKET_EVENT.SPONSOR_LEAVE, res => {
    callback(res);
  });
}

export function muteSponsorLeave() {
  socket.off(SOCKET_EVENT.SPONSOR_LEAVE);
}
