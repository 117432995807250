import { notification } from 'antd';
import { updateQuestion, queryDetail } from '@/services/question';
import { getFeelingQuestion } from '@/services/survey';

function* updateFeelingQuestion({ payload }, { call, put }) {
  try {
    const { pulseId, questionId, question, options = [] } = payload;
    const response = yield call(updateQuestion, pulseId, questionId, question, options);
    if (!response.isSuccess) return notification.error({ message: 'Update question failure' });
    const result = yield call(getFeelingQuestion, pulseId);
    yield put({
      type: 'appendFeeling',
      payload: result.data,
    });
    const responseListQuestion = yield call(queryDetail, pulseId);
    if (responseListQuestion.isSuccess) {
      const { data } = responseListQuestion;

      yield put({
        type: 'survey/appendGeneralInfo',
        payload: { updatedAt: data.generalInfo.updatedAt },
      });
    }
    return notification.success({ message: 'Update question successfully' });
  } catch (error) {
    console.error(error);
  }
}
export default updateFeelingQuestion;
