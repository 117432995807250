import { notification } from 'antd';
import { serviceGetPulseSponsors } from '@/services/singlePulse';

function* getPulseSponsors({ payload }: any, { call, put }: any) {
  try {
    const { pulseId, search, callback } = payload;
    const { meta, data } = yield call(serviceGetPulseSponsors, pulseId, search);

    if (meta?.ok) {
      const { doneSponsors = [], undoneSponsors = [] } = data;

      yield put({
        type: 'savePulseSponsors',
        payload: { sponsors: [...doneSponsors, ...undoneSponsors] },
      });

      if (typeof callback === 'function') {
        callback();
      }

      return;
    }

    notification.error({ message: 'Failed to get pulse sponsors', description: meta?.message });
  } catch (error) {
    notification.error({ message: 'Something went wrong' });
  }
  return true;
}

export default getPulseSponsors;
