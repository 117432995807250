import { Question } from '@/types/question';
import { Resource } from '@/types/resource';
import { formatPulseTemplateData } from '@/utils/formatPulseTemplateData';

export default {
  saveResourceList(
    state: ResourceModel.ResourceModelState,
    { payload }: { payload: ResourceModel.SaveResourceListPayload }
  ) {
    return {
      ...state,
      listResource: {
        list: payload?.listResources,
        pagination: payload?.pagination,
      },
    };
  },
  saveCurrentResource(state: ResourceModel.ResourceModelState, { payload }: { payload: Resource }) {
    return {
      ...state,
      currentResource: payload,
    };
  },
  saveListQuestionPulseTemplate(
    state: ResourceModel.ResourceModelState,
    { payload }: { payload: Question[] }
  ) {
    return {
      ...state,
      currentPulseTemplate: {
        ...state.currentPulseTemplate,
        questionList: payload,
      },
    };
  },
  saveQuestionBeforeBranching(
    state: ResourceModel.ResourceModelState,
    { payload }: { payload: Question[] }
  ) {
    return {
      ...state,
      currentPulseTemplate: {
        ...state.currentPulseTemplate,
        questionBeforeBranching: payload,
      },
    };
  },
  saveAvailableBranches(
    state: ResourceModel.ResourceModelState,
    { payload }: { payload: string[] }
  ) {
    return {
      ...state,
      currentPulseTemplate: {
        ...state.currentPulseTemplate,
        availableBranches: payload,
      },
    };
  },
  saveFeelingPulseTemplate(
    state: ResourceModel.ResourceModelState,
    { payload }: { payload: Question }
  ) {
    return {
      ...state,
      currentPulseTemplate: {
        ...state.currentPulseTemplate,
        feelingQuestion: payload,
      },
    };
  },
  removeFeelingPulseTemplate(state: ResourceModel.ResourceModelState) {
    return {
      ...state,
      currentPulseTemplate: {
        ...state.currentPulseTemplate,
        feelingQuestion: null,
      },
    };
  },
  saveCurrentPulseTemplate(state: ResourceModel.ResourceModelState, {payload} : { payload: Question[]}) {
    const { feelingQuestion, availableBranches, questionBeforeBranching, questionList} = formatPulseTemplateData(payload);
    return {
      ...state,
      currentPulseTemplate: {
        feelingQuestion,
        questionList,
        availableBranches,
        questionBeforeBranching,
      },
    };
  },
  clearCurrentPulseTemplate(state: ResourceModel.ResourceModelState) {
    return {
      ...state,
      currentPulseTemplate: {
        feelingQuestion: null,
        questionList: [],
        availableBranches: [],
        questionBeforeBranching: [],
      },
    };
  },
};
