import { notification } from 'antd';
import { deleteQuestion as deleteQuestionService, queryDetail } from '@/services/question';
import ConfigSite from '@/../config/configSite';

const { QUESTION_TYPE } = ConfigSite;

function* deleteQuestion({ payload }, { call, put }) {
  const { questionId, pulseId, questionType, callback } = payload;
  try {
    const response = yield call(deleteQuestionService, pulseId, questionId);
    if (!response.isSuccess) return notification.error({ message: 'Something went wrong' });

    const response1 = yield call(queryDetail, pulseId);
    if (!response1.isSuccess) return notification.error({ message: 'Something went wrong' });
    const { data } = response1;
    yield put({
      type: 'survey/appendGeneralInfo',
      payload: { updatedAt: data.generalInfo.updatedAt },
    });
    yield put({
      type: 'removeQuestion',
      payload: { questionId, generalInfo: data.generalInfo },
    });
    yield put({
      type: 'getAvailableBranches',
      payload: pulseId,
    });
    if (questionType === QUESTION_TYPE.BRANCHING) {
      yield put({
        type: 'setQuestionBeforeBranching',
        payload: [],
      });
    }
    notification.success({ message: 'Remove question successfully' });
  } catch (error) {
    return notification.error({ message: 'Something went wrong' });
  } finally {
    if (callback) callback(false);
  }
}
export default deleteQuestion;
