import { notification } from 'antd';
import { eventChannel } from 'redux-saga';
import {
  sponsorConnectLive,
  disconnectSocket,
  listenPulseStatusChange,
  listenQuestionChange,
  listenSubmittedSponsorChange,
  listenSummaryChange,
  listenTotalSponsorChange,
  liveOff,
  liveOn,
  liveQuestion,
  muteChangeQuestion,
  muteSubmittedSponsorChange,
  submitAnswer,
  sponsorReaction,
  listenReactionService,
  muteListenReactionService,
  mutePulseStatusChange,
  muteTotalSponsorChange,
  muteSummaryChange,
  listenLiveOff,
  muteLiveOff,
  muteNewSponsorJoin,
  listenNewSponsorJoin,
  listenSponsorLeave,
  muteSponsorLeave,
  collectorConnectSocket,
} from '@/services/livemode';
import { queryDetail } from '@/services/question';
import {
  getLiveStatus,
  getLiveSummary,
  getSponsorsJoinedLive,
  getSubmissionStatus,
  sponsorGetReactionState,
} from '@/services/liveInsight';
import { cloneDeep } from 'lodash';

const defaultState = {
  questionList: [],
  questionIdWhenClickRadio: -1,
  isLived: false,
  isClickQuestion: false,
  numberOfAnswer: 0,
  allSponsors: 0,
  pulseInfo: {},
  currentQuestion: {},
  currentSubmissionId: null,
  summaryLive: {},
  submissionStatus: {},
  reactionLive: {},
  reaction: '',
  joinedLiveSponsors: [],
  isConnectedSocket: false,
};

const LiveInsight = {
  namespace: 'liveInsight',
  state: {
    ...defaultState,
  },
  effects: {
    *getQuestion({ payload }, { call, put }) {
      // Get list question
      const res = yield call(queryDetail, payload);
      if (res.isSuccess) {
        yield put({
          type: 'appendQuestionList',
          payload: res.data.body,
        });
        yield put({
          type: 'appendPulseInfo',
          payload: res.data.generalInfo,
        });
        yield put({
          type: 'saveCurrentQuestion',
          payload: { question: res.data.body[0] },
        });
      } else {
        notification.error({ message: res.getErrorMessage('Failed to get questions') });
      }
    },
    *getLiveStatus({ payload }, { call, put }) {
      try {
        const res = yield call(getLiveStatus, payload.pulseId);

        if (res.isSuccess) {
          yield put({
            type: 'toggleLive',
            payload: res.data?.isLive,
          });
          yield put({
            type: 'saveAllSponsors',
            payload: res.data?.totalSponsor,
          });
          yield put({
            type: 'saveQuestionID',
            payload: {
              questionId: res.data?.liveQuestionId || -1,
            },
          });
        } else {
          notification.error({ message: res.getErrorMessage('Failed to get questions') });
        }
      } catch (err) {
        console.error(err);
      }
    },
    *getLiveStatusCollector({ payload }, { call, put }) {
      try {
        const { pulseId } = payload;
        const res = yield call(getLiveStatus, payload.pulseId);

        if (res.isSuccess) {
          if (res.data?.liveQuestionId >= 0) {
            yield put({
              type: 'getLiveSummary',
              payload: {
                pulseId,
                questionId: res.data?.liveQuestionId,
              },
            });
          }

          yield put({
            type: 'toggleLive',
            payload: res.data?.isLive,
          });
          yield put({
            type: 'saveAllSponsors',
            payload: res.data?.totalSponsor,
          });
          yield put({
            type: 'saveQuestionID',
            payload: {
              questionId: res.data?.liveQuestionId || -1,
            },
          });
        } else {
          notification.error({ message: res.getErrorMessage('Failed to get questions') });
        }
      } catch (err) {
        console.error(err);
      }
    },
    *getLiveSummary({ payload }, { call, put }) {
      try {
        const res = yield call(getLiveSummary, payload);

        if (res.isSuccess) {
          yield put({
            type: 'saveSummaryLive',
            payload: {
              summaryLive: res.data,
            },
          });
          yield put({
            type: 'saveNumberOfAnswer',
            payload: res.data?.totalSponsorAnswer,
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
    *getSubmissionStatus({ payload }, { call, put }) {
      try {
        const res = yield call(getSubmissionStatus, payload);
        if (res.isSuccess) {
          yield put({
            type: 'saveSubmissionStatus',
            payload: {
              submissionStatus: res.data,
            },
          });
          yield put({
            type: 'saveCurrentSubmissionId',
            payload: {
              submissionId: res.data?.submissionId,
            },
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
    *getSponsorsJoinedLive({ payload }, { call, put }) {
      try {
        const res = yield call(getSponsorsJoinedLive, payload);
        if (res.isSuccess) {
          yield put({
            type: 'saveJoinedLiveSponsors',
            payload: {
              joinedLiveSponsors: res.data?.sponsors,
            },
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
    *collectorConnectSocket(action, { call }) {
      try {
        const { pulseId, dispatch } = action.payload;
        yield call(collectorConnectSocket, pulseId, dispatch);
      } catch (error) {
        console.error(error);
      }
    },
    *sponsorConnectLive(action, { call }) {
      const { pulseId, dispatch, slug } = action.payload;
      try {
        yield call(sponsorConnectLive, { pulseId, slug, dispatch });
      } catch (error) {
        console.error(error);
      }
    },
    *disconnectSocket(_, { call }) {
      try {
        yield call(disconnectSocket);
      } catch (error) {
        console.error(error);
      }
    },

    *liveOn(action, { call, put }) {
      try {
        yield call(liveOn, action.payload);
        yield put({
          type: 'toggleLive',
          payload: true,
        });
      } catch (error) {
        console.error(error);
      }
    },
    *liveOff(action, { call, put }) {
      try {
        yield call(liveOff, action.payload);
        yield put({
          type: 'toggleLive',
          payload: false,
        });
        yield put({ type: 'disconnectSocket' });
      } catch (error) {
        console.error(error);
      }
    },
    *liveQuestion(action, { put, call }) {
      try {
        yield call(liveQuestion, action.payload);
        yield put({
          type: 'saveQuestionID',
          payload: {
            questionId: action.payload.questionId,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    *submitAnswer(action, { put, call }) {
      try {
        const res = yield call(submitAnswer, action.payload);

        if (res?.meta.ok) {
          yield put({
            type: 'saveCurrentSubmissionId',
            payload: {
              submissionId: res.data?.submissionId,
            },
          });
          yield put({
            type: 'updateDoneQuestion',
            payload: {
              questionId: action.payload.questionId,
            },
          });
          notification.success({ message: 'Answer was sent' });
        } else {
          notification.error({ message: res?.meta?.message });
        }
      } catch (error) {
        console.error(error);
      }
    },
    *listenQuestionChange(_, { put, call, take }) {
      try {
        // Create event channel
        const createChangeQuestionChannel = () =>
          eventChannel(emit => {
            const handler = data => {
              emit(data);
            };
            listenQuestionChange(handler);
            return () => {
              muteChangeQuestion();
            };
          });

        const changeQuestionChannel = yield call(createChangeQuestionChannel);
        while (true) {
          const payload = yield take(changeQuestionChannel);
          yield put({
            type: 'saveQuestionID',
            payload: {
              questionId: payload.questionId,
            },
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    *listenSummaryChange(_, { put, call, take }) {
      try {
        // Create event channel
        const createChangeSummaryChannel = () =>
          eventChannel(emit => {
            const handler = data => {
              emit(data);
            };
            listenSummaryChange(handler);
            return () => {
              muteSummaryChange();
            };
          });

        const changeSummaryChannel = yield call(createChangeSummaryChannel);

        while (true) {
          const payload = yield take(changeSummaryChannel);
          yield put({
            type: 'saveSummaryLive',
            payload: {
              summaryLive: payload,
            },
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    *listenTotalSponsorChange(_, { put, call, take }) {
      try {
        // Create event channel
        const createChangeTotalSponsorChannel = () =>
          eventChannel(emit => {
            const handler = data => {
              emit(data);
            };
            listenTotalSponsorChange(handler);
            return () => {
              muteTotalSponsorChange();
            };
          });

        const changeTotalSponsorChannel = yield call(createChangeTotalSponsorChannel);

        while (true) {
          const payload = yield take(changeTotalSponsorChannel);
          yield put({
            type: 'saveAllSponsors',
            payload: payload?.totalSponsor,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },

    *sponsorGetReaction(action, { call, put }) {
      const { questionId, slug } = action.payload;
      try {
        const { meta, data } = yield call(sponsorGetReactionState, slug, questionId);
        if (meta?.ok) {
          yield put({
            type: 'saveReaction',
            payload: { reaction: data?.reactionType ? data?.reactionType : '' },
          });
        }
      } catch (error) {
        console.error(error);
      }
    },

    *listenSubmittedSponsorChange(_, { put, call, take }) {
      try {
        // Create event channel
        const createChangeSubmittedSponsorChannel = () =>
          eventChannel(emit => {
            const handler = data => {
              emit(data);
            };
            listenSubmittedSponsorChange(handler);
            return () => {
              muteSubmittedSponsorChange();
            };
          });

        const changeSubmittedSponsorChannel = yield call(createChangeSubmittedSponsorChannel);

        while (true) {
          const payload = yield take(changeSubmittedSponsorChannel);
          yield put({
            type: 'saveNumberOfAnswer',
            payload: payload?.submittedSponsor,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    *listenReaction(action, { call, put, take }) {
      try {
        // Create event channel
        const createListionChannelReaction = () =>
          eventChannel(emit => {
            const handler = data => {
              emit(data);
            };
            listenReactionService(handler);
            return () => {
              muteListenReactionService();
            };
          });

        const listenChannelReaction = yield call(createListionChannelReaction);
        while (true) {
          const payload = yield take(listenChannelReaction);
          yield put({
            type: 'saveReactionLive',
            payload: {
              reactionLive: payload,
            },
          });
        }
      } catch (error) {
        console.error(error);
      }
    },

    *listenPulseStatusChange(_, { put, call, take }) {
      try {
        // Create event channel
        const createChangePulseStatusChannel = () =>
          eventChannel(emit => {
            const handler = data => {
              emit(data);
            };
            listenPulseStatusChange(handler);
            return () => {
              mutePulseStatusChange();
            };
          });

        const changePulseStatusChannel = yield call(createChangePulseStatusChannel);

        while (true) {
          const payload = yield take(changePulseStatusChannel);
          yield put({
            type: 'survey/updateSurveyStatus',
            payload: payload?.newStatus,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    *listenLiveOff(_, { put, call, take }) {
      try {
        // Create event channel
        const createLiveOffChannel = () =>
          eventChannel(emit => {
            const handler = data => {
              emit(data);
            };
            listenLiveOff(handler);
            return () => {
              muteLiveOff();
            };
          });

        const liveOffChannel = yield call(createLiveOffChannel);

        while (true) {
          const payload = yield take(liveOffChannel);
          yield put({
            type: 'toggleLive',
            payload: payload?.liveMode === 1,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    *listenNewSponsorJoin(_, { put, call, take }) {
      try {
        // Create event channel
        const createNewSponsorJoinChannel = () =>
          eventChannel(emit => {
            const handler = data => {
              emit(data);
            };
            listenNewSponsorJoin(handler);
            return () => {
              muteNewSponsorJoin();
            };
          });

        const newSponsorJoinChannel = yield call(createNewSponsorJoinChannel);

        while (true) {
          const payload = yield take(newSponsorJoinChannel);
          yield put({
            type: 'updateJoinedLiveSponsors',
            payload: {
              sponsor: payload,
            },
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    *listenSponsorLeave(_, { put, call, take }) {
      try {
        // Create event channel
        const createSponsorLeaveChannel = () =>
          eventChannel(emit => {
            const handler = data => {
              emit(data);
            };
            listenSponsorLeave(handler);
            return () => {
              muteSponsorLeave();
            };
          });

        const sponsorLeaveChannel = yield call(createSponsorLeaveChannel);

        while (true) {
          const payload = yield take(sponsorLeaveChannel);
          yield put({
            type: 'updateLeftLiveSponsors',
            payload: {
              sponsor: payload,
            },
          });
        }
      } catch (error) {
        console.error(error);
      }
    },

    *sponsorReaction(action, { call, put }) {
      try {
        const { pulseId, questionId, reactionType } = action.payload;
        yield call(sponsorReaction, pulseId, questionId, reactionType);
        yield put({
          type: 'saveReaction',
          payload: { reaction: reactionType },
        });
      } catch (error) {
        console.error(error);
      }
    },
  },

  reducers: {
    resetLiveInsight() {
      return {
        ...defaultState,
      };
    },
    appendQuestionList(state, action) {
      return {
        ...state,
        questionList: action.payload,
      };
    },
    addFeelingQuestion(state, action) {
      return {
        ...state,
        questionList: action.payload,
      };
    },
    appendPulseInfo(state, action) {
      return {
        ...state,
        pulseInfo: action.payload,
      };
    },
    saveQuestionID(state, action) {
      const {
        payload: { questionId },
      } = action;
      return {
        ...state,
        questionIdWhenClickRadio: questionId,
      };
    },
    saveSummaryLive(state, action) {
      const {
        payload: { summaryLive },
      } = action;
      return {
        ...state,
        summaryLive,
      };
    },
    saveCurrentSubmissionId(state, action) {
      const {
        payload: { submissionId },
      } = action;
      return {
        ...state,
        currentSubmissionId: submissionId,
      };
    },
    saveCurrentQuestion(state, action) {
      const {
        payload: { question },
      } = action;
      return {
        ...state,
        currentQuestion: question,
      };
    },
    saveSubmissionStatus(state, action) {
      const {
        payload: { submissionStatus },
      } = action;
      return {
        ...state,
        submissionStatus,
      };
    },
    saveJoinedLiveSponsors(state, action) {
      const {
        payload: { joinedLiveSponsors },
      } = action;
      return {
        ...state,
        joinedLiveSponsors,
      };
    },
    updateJoinedLiveSponsors(state, action) {
      const {
        payload: { sponsor },
      } = action;
      const newJoinedLiveSponsors = cloneDeep(state.joinedLiveSponsors);
      const foundIndex = newJoinedLiveSponsors.findIndex(item => item.userId === sponsor?.userId);
      if (foundIndex < 0) newJoinedLiveSponsors.push(sponsor);

      return {
        ...state,
        joinedLiveSponsors: [...newJoinedLiveSponsors],
      };
    },
    updateLeftLiveSponsors(state, action) {
      const {
        payload: { sponsor },
      } = action;
      const newJoinedLiveSponsors = cloneDeep(state.joinedLiveSponsors);
      const result = newJoinedLiveSponsors.filter(item => item.userId !== sponsor?.userId);
      return {
        ...state,
        joinedLiveSponsors: [...result],
      };
    },
    updateDoneQuestion(state, action) {
      const {
        payload: { questionId },
      } = action;
      const newSubmissionStatus = cloneDeep(state.submissionStatus);
      newSubmissionStatus.doneQuestionIds?.push(questionId);
      const undone = newSubmissionStatus.undoneQuestionIds?.filter(item => item !== questionId);
      newSubmissionStatus.undoneQuestionIds = undone;
      return {
        ...state,
        submissionStatus: newSubmissionStatus,
      };
    },
    toggleLive(state, action) {
      return {
        ...state,
        isLived: action.payload,
      };
    },
    toggleClickQuestion(state, action) {
      return {
        ...state,
        isClickQuestion: action.payload,
      };
    },
    saveNumberOfAnswer(state, action) {
      return {
        ...state,
        numberOfAnswer: action.payload,
      };
    },
    saveAllSponsors(state, action) {
      return {
        ...state,
        allSponsors: action.payload,
      };
    },
    saveStatusPulse(state, action) {
      return {
        ...state,
        StatusPulse: action.payload,
      };
    },
    setConnectedSocket(state, action) {
      return {
        ...state,
        isConnectedSocket: action.payload,
      };
    },
    saveReaction(state, action) {
      return {
        ...state,
        reaction: action.payload.reaction,
      };
    },
    saveReactionLive(state, action) {
      return {
        ...state,
        reactionLive: action.payload.reactionLive,
      };
    },
  },
};

export default LiveInsight;
