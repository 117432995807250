import changePulseStatus from './changePulseStatus';
import createPulse from './createPulse';
import getPulse from './getPulse';
import getPulses from './getPulses';
import updatePulseConfig from './updatePulseConfig';
import getPulseSponsors from './getPulseSponsors';
import deletePulseSponsors from './deletePulseSponsors';
import getPulseCollectors from './getPulseCollectors';
import addPulseSponsors from './addPulseSponsors';
import responsePulseSponsorsRequest from './responsePulseSponsorsRequest';
import addPulseCollectors from './addPulseCollectors';
import deletePulseCollector from './deletePulseCollector';
import exportPulses from './exportPulses';

export default {
  changePulseStatus,
  createPulse,
  getPulse,
  getPulses,
  updatePulseConfig,
  getPulseSponsors,
  getPulseCollectors,
  deletePulseSponsors,
  addPulseSponsors,
  responsePulseSponsorsRequest,
  addPulseCollectors,
  deletePulseCollector,
  exportPulses,
};
