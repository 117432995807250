import { notification } from 'antd';
import { queryDetail, deleteQuestion } from '@/services/question';

function* deleteFeelingQuestion({ payload }, { call, put }) {
  try {
    const { pulseId, questionId, callback } = payload;
    const response = yield call(deleteQuestion, pulseId, questionId);

    const responseDetail = yield call(queryDetail, pulseId);
    if (responseDetail.isSuccess) {
      const { data } = responseDetail;
      yield put({
        type: 'survey/appendGeneralInfo',
        payload: { updatedAt: data.generalInfo.updatedAt },
      });

      yield put({
        type: 'appendFeeling',
        payload: {
          isRequired: 0,
        },
      });

      notification.success({ message: 'Remove question successfully' });
      if (typeof callback === 'function') {
        callback();
      }
    }
  } catch (error) {
    notification.success({ message: 'Something went wrong' });
  }
}

export default deleteFeelingQuestion;
