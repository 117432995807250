import { search as searchCollection } from '@/services/collection';

function* search({ payload }, { call, put }) {
  const response = yield call(searchCollection, payload);
  if (response.isSuccess) {
    yield put({
      type: 'saveCollectionSearch',
      payload: {
        meta: response.meta,
        data: response.data,
      },
    });
  }
}

export default search;
