import React from 'react';
import { message } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

/**
 *
 * @param {ReactNode} content :  The content of the message. ReactNode is accepted;
 * @param {number} duration:Time(seconds) before auto-dismiss, don't dismiss if set to 0
 * @param {function} onClose: Specify a function that will be called when the message is closed
 */
const messageSuccess = (content, duration = 3, onClose = () => {}) => {
  message.success({ icon: <CheckOutlined />, content, duration, onClose });
};

export default messageSuccess;
