/* eslint-disable no-underscore-dangle */
/* eslint-disable no-empty */
/**
 * request
 * api: https://github.com/umijs/umi-request
 */
import { extend } from 'umi-request';
import { history } from 'umi';
import { notification } from 'antd';
import { getToken, removeTokenAuthority } from '@/utils/authority';

class Response {
  data = null;

  meta = null;

  constructor(response) {
    if (response.meta) {
      this.meta = response.meta;
    }
    if (response.data) {
      this.data = response.data;
    }
    if (this.meta === null && this.data === null) {
      this.data = response;
      this.meta = { ok: true };
    }
  }

  get isSuccess() {
    return this.meta && this.meta.ok;
  }

  getErrorMessage(defaultMessage = 'Something went wrong!') {
    return (this.meta && this.meta.message) || defaultMessage;
  }
}

const errorHandler = async error => {
  const { response = {} } = error;
  const { status } = response;
  if (status === 401) {
    notification.error({
      message: 'Please login to do this',
    });
    removeTokenAuthority();
    /* eslint-disable no-underscore-dangle */
    // window.g_app._store.dispatch({
    //   type: 'login/logout',
    // });
  }
  // environment should not be used
  if (status === 403) {
    let messageError = 'You are not allowed to access this page';
    if (typeof response.json === 'function') {
      const { message } = await response.json();
      messageError = message || messageError;
    }
    if (/^\/admin/.test(window.location.pathname)) {
      history.push({ pathname: '/admin/403', state: { message: messageError } });
    } else {
      notification.error({
        message: messageError,
      });
    }
    notification.destroy();
    // window.g_app._store.dispatch({
    //   type: 'user/fetchCurrent',
    // });
  }

  // if (status <= 504 && status >= 500) {
  //   notification.error({
  //     message: 'Please try login again, contact admin if you still see this message',
  //   });
  // window.g_app._store.dispatch({
  //   type: 'login/logout',
  // });

  if (status >= 404 && status < 422) {
    notification.error({
      message: `Error ${status}`,
    });
  }
  return new Response({ meta: { ok: false } });
};

const request = extend({
  errorHandler,
  credentials: 'include',
  prefix: API_URL,
});

request.interceptors.request.use((url, options) => {
  const authority = getToken();
  const timezone = Intl ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'Asia/Saigon';
  return {
    url,
    options: {
      ...options,
      headers: {
        ...options.headers,
        timezone,
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: (authority && `Bearer ${authority}`) || undefined,
      },
    },
  };
});

request.interceptors.response.use(async (response, options) => {
  if (response.ok) {
    if (options.responseType === 'blob') {
      try {
        const blob = await response.clone().blob();
        return new Response({ data: blob });
      } catch (error) {}
    }
    try {
      const json = await response.clone().json();
      return new Response(json);
    } catch (error) {}
    try {
      const text = await response.clone().text();
      return new Response({ data: text });
    } catch (error) {}
  }
  return response;
});

const getRequest = options => {
  return extend({
    ...request,
    ...options,
    prefix: API_URL,
  });
};

export default request;

export { getRequest, Response, request };
