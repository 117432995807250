import { serviceDetachPulses } from '@/services/collection';
import { notification } from 'antd';

interface IGeneratorDetachPulses {
  payload: {
    collectionId: string;
    pulseIds: (string | number)[];
    callback?: () => void;
  };
}

function* generatorDetachPulses({ payload }: IGeneratorDetachPulses, { call }: any) {
  const { pulseIds, collectionId, callback } = payload;
  try {
    const { meta } = yield call(serviceDetachPulses, collectionId, pulseIds);
    if (!meta.ok)
      return notification.error({ message: `Failed to detach pulses`, description: meta?.message });
    notification.success({
      message: `Detach pulses successfully`,
    });
    if (typeof callback === 'function') {
      callback();
    }
  } catch (error) {
    notification.error({ message: `Failed to detach pulses` });
  }
  return true;
}

export default generatorDetachPulses;
