import { notification } from 'antd';
import { reOrderQuestion, queryDetail } from '@/services/question';
import ConfigSite from '@/../config/configSite';

const { QUESTION_TYPE } = ConfigSite;

function* updateOrder({ payload }, { call }) {
  const { questionId, order, pulseId, callback } = payload;
  try {
    const { meta } = yield call(reOrderQuestion, questionId, order, pulseId);

    if (!meta.ok) {
      return notification.error({
        message: 'Failed to update',
      });
    }

    if (typeof callback === 'function') {
      callback();
    }

    return notification.success({
      message: 'Updated successfully',
    });
  } catch (error) {
    notification.error({ message: 'Something went wrong' });
  }
}

export default updateOrder;
