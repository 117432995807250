import { stringify } from 'qs';
import request from '@/utils/request';
import ConfigSite from '../../config/configSite';

export async function query(params) {
  if (!params) {
    params = {
      page: 1,
      limit: ConfigSite.LIMIT,
    };
  }
  return request(`/collector?${stringify(params)}`);
}

export async function create(params) {
  return request('/collector', {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

export async function updateCollector(slug, params) {
  return request(`/collector/${slug}/update`, {
    method: 'PUT',
    body: JSON.stringify(params),
  });
}

export async function updateStatus(slug, status) {
  return request.put(`/collector/${slug}/status/${status}`);
}

export async function exportCollector(params) {
  return request(`/collector/${params}/export`);
}

export async function getExportFile(params) {
  return request(`/collector/${params}/exportFile`, { responseType: 'blob' });
}
/**
 *
 * @param {number} collectionId
 * @param {number} collectorId
 * @returns
 */

export async function removeCollector(collectionId, collectorId) {
  return request(`/collections/${collectionId}/collectors`, {
    method: 'DELETE',
    body: JSON.stringify({ collectorId }),
  });
}
