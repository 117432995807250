import { fetchGeneralInfoSponsor } from '@/services/survey';
import { notification } from 'antd';

interface IInfoPreview {
  payload: { slug: string; isPreview: number };
}

function* generatorInfoPreview({ payload }: IInfoPreview, { call, put }: any) {
  const { slug, isPreview } = payload;
  try {
    const { meta, data } = yield call(fetchGeneralInfoSponsor, slug, isPreview);
    if (meta.ok) {
      yield put({ type: 'saveDataPreview', payload: { generalInfo: data } });
    } else {
      notification.error({ message: 'something went wrong' });
    }
  } catch (error) {
    notification.error({ message: 'something went wrong' });
  }
}

export default generatorInfoPreview;
