import request from '@/utils/request';

export async function register(params) {
  return request('/auth/register', {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

export async function registerAccountV2(params) {
  return request('/auth/v2/register', {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

export async function login(params) {
  return request('/auth/login', {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

export async function loginWithSmartToken(params) {
  return request('/api/auth/smart-link', {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

export async function query() {
  return request('/users');
}
export async function fetchInfo(jwt) {
  return request('/users', {
    method: 'POST',
    body: JSON.stringify(jwt),
  });
}

export async function queryNotices() {
  return request('/notices');
}

export async function getJWT(params) {
  return request(`/oauth/callback?${params}`);
}

export async function loginAdmin(params) {
  return request(`/auth/login/admin`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

export async function getAnonymousName() {
  return request('/users/guest/generate');
}

export async function loginExternalMember(objEmail) {
  return request(`/auth/login/externalMember`, {
    method: 'POST',
    body: JSON.stringify(objEmail),
  });
}

export async function fetchMembers() {
  return request(`/users/list`);
}

export async function checkEmailExsits(email) {
  return request(`/auth/check-email?email=${email}`);
}

export async function checkToken(token) {
  return request(`/auth/check-token?token=${token}`);
}

export async function setupPassword(token, password) {
  return request(`/auth/setup-password?token=${token}`, {
    method: 'POST',
    body: JSON.stringify({ password }),
  });
}

export async function forgotPassword(email, redirect) {
  return request('/auth/forgot-password', {
    method: 'POST',
    body: JSON.stringify({ email, redirect }),
  });
}

export async function loginWithGoogle({ token }) {
  return request('/sauth/google', {
    method: 'POST',
    headers: {
      platform: 'web',
    },
    body: JSON.stringify({ token }),
  });
}

export async function loginWithApple({ token, email }) {
  return request('/sauth/apple', {
    method: 'POST',
    headers: {
      platform: 'web',
    },
    body: JSON.stringify({ token, email }),
  });
}

export async function getAnonymousToken(code) {
  return request(`/sponsors/pulses/${code}/anonymous-token`, {
    method: 'GET',
  });
}
