import effects from './effects';
import reducers from './reducers';

const initialState: QuestionModel.QuestionModelState = {
  questions: {
    generalInfo: {
      updatedAt: 0,
      surveyId: -1,
      surveyInfo: '',
      surveyTitle: '',
      surveyStatus: 0,
      slug: '',
      allowAnonymous: 0,
      surveyType: 0,
      surveyKey: null,
      pulseType: 0,
      isEventSurvey: false,
      feelingQuestion: 0,
      surveyRelation: {},
      settings: {
        allowMultiAnswer: 0,
        autoAccept: 0,
        autoConfirm: 0,
        filterType: 0,
        purposeType: 0,
      },
    },
    body: [],
  },
  list: [],
  feeling: null,
  defaultQuestion: null,
  availableBranches: [],
  questionsBeforeBranching: [],
  keywords: [],
  generalInfo: {
    updatedAt: 0,
    surveyId: -1,
    surveyInfo: '',
    surveyTitle: '',
    surveyStatus: 0,
    slug: '',
    allowAnonymous: 0,
    surveyType: 0,
    surveyKey: null,
    pulseType: 0,
    isEventSurvey: false,
    feelingQuestion: 0,
    surveyRelation: {},
    settings: {
      allowMultiAnswer: 0,
      autoAccept: 0,
      autoConfirm: 0,
      filterType: 0,
      purposeType: 0,
    },
  },
};
const QuestionModel  = {
  namespace: 'question',
  state: initialState,
  effects,
  reducers,
};

export default QuestionModel;
