import { notification } from 'antd';
import messageSuccess from '@/components/CustomizedMessage/success';
import { addSponsor as addSponsorService } from '@/services/collection';

function* addSponsor({ payload }, { call, put, select }) {
  const id = yield select(state => state.collection.data.id || null);
  if (!id) {
    notification.error({ message: 'Failed to sponsor into collection' });
  } else {
    const { data, formRef } = payload;
    const body = { sponsors: data };
    const res = yield call(addSponsorService, id, body);
    if (formRef) {
      formRef.current.resetFields();
    }

    if (!res.isSuccess) {
      notification.error({ message: res.getErrorMessage('Failed to sponsor into collection') });
    } else {
      yield put({
        type: 'details',
        payload: id,
      });
      messageSuccess('You have successfully added sponsor into collection');
    }
  }
}

export default addSponsor;
