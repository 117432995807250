import { notification } from 'antd';
import {
  getGeneralInfo,
  getSponsorsJoinedInfo,
  getSponsorsInQuestion,
  getSummaryFile,
  getSummary,
  exportPDF,
} from '@/services/Summary';
import ConfigSite from '@/../config/configSite';

const { SUMMARY_TAB } = ConfigSite;

const PulseSummary = {
  namespace: 'pulseSummary',
  state: {
    summary: {},
    sponsorsInQuestion: {},
    generalInfo: {},
    sponsorsJoined: {},
    isLiveInsight: SUMMARY_TAB.SUMMARY,
    sponsorsReactList: [],
    selectedBranch: null,
  },
  effects: {
    *exportsPDF(action, { call }) {
      const { pulseId } = action.payload;
      const exportPdf = yield call(exportPDF, pulseId);
      if (!exportPdf.meta.ok) {
        notification.success({ message: exportPdf.meta.message || 'Failed to export' });
      }
    },
    *getSummary(action, { call, put }) {
      const { pulseId, collectionId } = action.payload;
      const response = yield call(getSummary, pulseId, collectionId);
      if (response.meta.ok) {
        yield put({
          type: 'savePulseSummary',
          payload: response.data,
        });
      } else {
        notification.error({ message: response.meta.message });
      }
    },

    *getListSponsorsInQuestion(action, { put, call }) {
      const response = yield call(getSponsorsInQuestion, action.payload);
      if (response.meta.ok) {
        yield put({
          type: 'saveListSponsorsInQuestion',
          payload: response.data,
        });
      } else {
        notification.error({ message: response.meta.message });
      }
    },
    *getPulseGeneralInfo(action, { put, call }) {
      const response = yield call(getGeneralInfo, action.payload);
      if (response.meta.status === 'SUCCESS') {
        yield put({
          type: 'savePulseGeneralInfo',
          payload: response.data,
        });
      }
    },
    *getSponsorsJoined(action, { put, call }) {
      const response = yield call(getSponsorsJoinedInfo, action.payload);
      if (response.isSuccess) {
        yield put({
          type: 'saveSponsorsInfo',
          payload: response.data,
        });
      } else {
        notification.error({ message: response.meta.message });
      }
    },
    *getSummaryFile(action, { call, select }) {
      const summary = yield select(state => state.pulseSummary.summary);
      const response = yield call(getSummaryFile, action.payload);
      if (response.data) {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        // the filename you want
        link.download = `summary_file_${summary.pulseId}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
  },
  reducers: {
    savePulseSummary(state, action) {
      return {
        ...state,
        summary: action.payload,
      };
    },
    saveListSponsorsInQuestion(state, action) {
      return {
        ...state,
        sponsorsInQuestion: action.payload,
      };
    },
    savePulseGeneralInfo(state, action) {
      return {
        ...state,
        generalInfo: action.payload,
      };
    },
    saveSponsorsInfo(state, action) {
      return {
        ...state,
        sponsorsJoined: action.payload,
      };
    },
    saveIsLiveInsight(state, action) {
      return {
        ...state,
        isLiveInsight: action.payload,
      };
    },
    resetSummaryData(state) {
      return {
        ...state,
        summary: {},
        sponsorsInQuestion: {},
        generalInfo: {},
        sponsorsJoined: {},
        isLiveInsight: SUMMARY_TAB.SUMMARY,
        sponsorsReactList: [],
        selectedBranch: null,
      };
    },
    saveSponsorsReactList(state, action) {
      return {
        ...state,
        sponsorsReactList: action.payload,
      };
    },
    saveSelectedBranch(state, action) {
      return {
        ...state,
        selectedBranch: action.payload,
      };
    },
  },
};

export default PulseSummary;
