import { notification } from 'antd';
import { deletePulseService } from '@/services/collection';

interface IDeletePulse {
  payload: { surveyId: string; callback: () => void };
}

function* generatorDeletePulse({ payload }: IDeletePulse, { call, put }: any) {
  const { surveyId, callback } = payload;
  try {
    const { meta } = yield call(deletePulseService, surveyId);
    if (!meta.ok) return notification.error({ message: 'Failure to delete pulse' });
    notification.success({ message: 'Delete pulse successfully' });
    yield put({ type: 'updatePulseList', payload: { surveyId } });
  } catch (error) {
    notification.error({ message: 'Failure to delete pulse' });
  } finally {
    callback();
  }
  return true;
}

export default generatorDeletePulse;
