import effects from './effects';
import reducers from './reducers';

const initialState = {
  pulses: [],
  pulse: {},
  pagination: {},
  collectors: [],
  sponsors: [],
};

export default {
  namespace: 'pulse',
  state: initialState,
  effects,
  reducers,
};
