import getFeelingQuestion from './getFeelingQuestion';
import getQuestion from './getQuestion';
import create from './create';
import update from './update';
import createFeelingQuestion from './createFeelingQuestion';
import deleteQuestion from './delete';
import deleteFeelingQuestion from './deleteFeelingQuestion';
import updateOrder from './updateOrder';
import getAvailableKeywords from './getAvailableKeywords';
import getAvailableBranches from './getAvailableBranches';
import updateFeelingQuestion from './updateFeelingQuestion';
import createQuestions from './createQuestions';
import getFeelingQuestionAdmin from './getFeelingQuestionAdmin';

export default {
  getQuestion,
  create,
  update,
  createFeelingQuestion,
  delete: deleteQuestion,
  deleteFeelingQuestion,
  getFeelingQuestion,
  updateFeelingQuestion,
  updateOrder,
  getAvailableBranches,
  getAvailableKeywords,
  createQuestions,
  getFeelingQuestionAdmin,
};
