import { CreatePulsePayload, Member } from '@/types/pulse';
import PULSE_ACTION_TYPE from './actionTypes';
import { FilterProps, PaginationProps } from '@/types/general';

export function createPulse(body: CreatePulsePayload, callback?: () => void) {
  return {
    type: PULSE_ACTION_TYPE.CREATE_PULSE,
    payload: {
      body,
      callback,
    },
  };
}

export function getPulse(id: string | number, callback?: () => void) {
  return {
    type: PULSE_ACTION_TYPE.GET_PULSE,
    payload: {
      id,
      callback,
    },
  };
}

export function getPulses(
  params: PaginationProps & FilterProps & { notInCollectionOnly?: boolean },
  callback?: () => void
) {
  return {
    type: PULSE_ACTION_TYPE.GET_PULSES,
    payload: {
      params,
      callback,
    },
  };
}

export function changePulseStatus(pulseId: string | number, status: number, callback?: () => void) {
  return {
    type: PULSE_ACTION_TYPE.CHANGE_PULSE_STATUS,
    payload: {
      pulseId,
      status,
      callback,
    },
  };
}

export function updatePulseConfig(
  pulseId: string | number,
  config: CreatePulsePayload,
  callback?: () => void
) {
  return {
    type: PULSE_ACTION_TYPE.UPDATE_PULSE_CONFIG,
    payload: {
      pulseId,
      config,
      callback,
    },
  };
}

export function getPulseSponsors(pulseId: string | number, search?: string, callback?: () => void) {
  return {
    type: PULSE_ACTION_TYPE.GET_PULSE_SPONSORS,
    payload: {
      pulseId,
      search,
      callback,
    },
  };
}

export function getPulseCollectors(
  pulseId: string | number,
  search?: string,
  callback?: () => void
) {
  return {
    type: PULSE_ACTION_TYPE.GET_PULSE_COLLECTORS,
    payload: {
      pulseId,
      search,
      callback,
    },
  };
}

export function addPulseCollectors(
  pulseId: string | number,
  collectors: Member[],
  callback?: () => void
) {
  return {
    type: PULSE_ACTION_TYPE.ADD_PULSE_COLLECTORS,
    payload: {
      pulseId,
      collectors,
      callback,
    },
  };
}

export function deletePulseCollector(
  pulseId: string | number,
  collectorId: string | number,
  callback?: () => void
) {
  return {
    type: PULSE_ACTION_TYPE.DELETE_PULSE_COLLECTOR,
    payload: {
      pulseId,
      collectorId,
      callback,
    },
  };
}

export function undoDeletePulseCollector() {
  return {
    type: PULSE_ACTION_TYPE.UNDO_DELETE_PULSE_COLLECTOR,
  };
}

export function deletePulseSponsors(
  pulseId: string | number,
  sponsorIds: (number | string)[],
  callback?: () => void
) {
  return {
    type: PULSE_ACTION_TYPE.DELETE_PULSE_SPONSORS,
    payload: {
      pulseId,
      sponsorIds,
      callback,
    },
  };
}

export function undoDeletePulseSponsors() {
  return {
    type: PULSE_ACTION_TYPE.UNDO_DELETE_PULSE_SPONSORS,
  };
}

export function addPulseSponsors(
  pulseId: string | number,
  sponsors: Member[],
  callback?: () => void
) {
  return {
    type: PULSE_ACTION_TYPE.ADD_PULSE_SPONSORS,
    payload: {
      pulseId,
      sponsors,
      callback,
    },
  };
}

export function responsePulseSponsorsRequest(
  pulseId: string | number,
  sponsorIds: (number | string)[],
  response: string,
  callback?: () => void
) {
  return {
    type: PULSE_ACTION_TYPE.RESPONSE_PULSE_SPONSORS_REQUEST,
    payload: {
      pulseId,
      sponsorIds,
      response,
      callback,
    },
  };
}

export function undoResponsePulseSponsorsRequest() {
  return {
    type: PULSE_ACTION_TYPE.UNDO_RESPONSE_PULSE_SPONSORS_REQUEST,
  };
}

export function exportPulses(
  pulseIds: (number | string)[],
  callback?: () => void
) {
  return {
    type: PULSE_ACTION_TYPE.EXPORT_PULSES,
    payload: {
      pulseIds,
      callback,
    },
  };
}

// Reducer's actions
export function clearPulseSponsors() {
  return {
    type: PULSE_ACTION_TYPE.SAVE_PULSE_SPONSORS,
    payload: {
      sponsors: [],
    },
  };
}

export function clearPulseCollectors() {
  return {
    type: PULSE_ACTION_TYPE.SAVE_PULSE_COLLECTORS,
    payload: {
      collectors: [],
    },
  };
}

export function clearPulses() {
  return {
    type: PULSE_ACTION_TYPE.SAVE_PULSES,
    payload: { pulses: [], pagination: {} },
  };
}
