import { produce } from 'immer';

import create from './create';
import fetch from './fetch';
import search from './search';
import duplicate from './duplicate';
import details from './details';
import update from './update';
import addSponsor from './addSponsor';
import addCollector from './addCollector';
import generatorDeletePulse from './generatorDeletePulse';
import generatorArchivedRestoreCollection from './generatorArchivedRestore';
import generatorInfoPreview from './generatorInfoPreview';
import generatorQuestionPreview from './generatorQuestionPreview';
import generatorFeelingQuestion from './generatorFeelingQuestion';
import generatorAttachPulses from './generatorAttachPulses';
import generatorDetachPulses from './generatorDetachPulses';
import ConfigSite from '../../../config/configSite';

export default {
  namespace: 'collection',
  state: {
    breadcrumb: [{ title: 'Collection list', href: '/admin/collection' }],
    collections: {
      data: [],
      pagination: {
        current: 1,
        pageSize: ConfigSite.LIMIT,
      },
    },
    preview: {
      generalInfo: {},
      questions: [],
    },
    CollectionLink: [],
    data: {},
    dataModify: {},
    status: false,
    dataSlug: {},
    unsaved: true,
  },

  effects: {
    create,
    fetch,
    search,
    duplicate,
    details,
    update,
    addSponsor,
    addCollector,
    generatorDeletePulse,
    generatorArchivedRestoreCollection,
    generatorInfoPreview,
    generatorQuestionPreview,
    generatorFeelingQuestion,
    generatorAttachPulses,
    generatorDetachPulses,
    *updateBreadcrumbs({ payload }, { put }) {
      const { data } = payload;
      yield put({
        type: 'appendBreadcrumb',
        payload: {
          data: data || [{ title: 'Collection list', href: '/admin/collection' }],
        },
      });
    },
  },
  reducers: {
    saveDataPreview(state, action) {
      return { ...state, preview: { ...state.preview, ...action.payload } };
    },
    append(state, action) {
      const {
        payload: { data },
      } = action;
      const { pagination, listPulseCollection } = data;
      return {
        ...state,
        collections: {
          pagination: {
            total: pagination.total || 0,
            current: pagination.offset / pagination.limit + 1,
          },
          data: listPulseCollection || [],
        },
        status: true,
      };
    },
    saveCollectionSearch(state, action) {
      const {
        payload: { data },
      } = action;
      return {
        ...state,
        collections: {
          ...state.collections,
          data,
        },
        status: true,
      };
    },
    appendDetails(state, action) {
      const {
        payload: { data, meta },
      } = action;
      return {
        ...state,
        data: data || [],
        status: meta.ok,
      };
    },
    updatePulseList(state, action) {
      const { surveyId } = action.payload;

      const nextState = produce(state?.data?.pulses, draft => {
        return draft.filter(item => item?.surveyId !== surveyId);
      });

      return {
        ...state,
        data: {
          ...state?.data,
          pulses: nextState,
        },
      };
    },
    appendBreadcrumb(state, action) {
      const {
        payload: { data },
      } = action;
      return {
        ...state,
        breadcrumb: data || [{ title: 'Collection list', href: '/admin/collection' }],
      };
    },
  },
  subscriptions: {
    listenUrlChanged({ history, dispatch }) {
      return history.listen(({ pathname }) => {
        if (/^\/admin\/collection/.test(pathname)) {
          // if (!/^\/admin\/collection\//.test(pathname)) {
          //   dispatch({ type: 'fetch', payload: getQueryUrl() });
          // }
          if (/^\/admin\/collection\/[0-9]+/.test(pathname)) {
            const matches = pathname.match(/\/[0-9]+/).join('');
            const id = matches && matches.match(/[0-9]+/);
            dispatch({ type: 'details', payload: id });
          }
        }
      });
    },
  },
};
